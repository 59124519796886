import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Switch,
	Typography,
} from '@mui/material';
import { useMenu } from '../../Context/MenuContext';
import { useEffect, useState } from 'react';

export default function GeolocationDialog({
	showOwnLocation,
	setShowOwnLocation,
}) {
	const { showGeolocationDialog, setShowGeolocationDialog } = useMenu();
	const [shareOwnLocation, setShareOwnLocation] = useState(false);
	const handleSaveSettings = () => {};
	return (
		<Dialog
			maxWidth='md'
			fullWidth
			open={showGeolocationDialog}
			onClose={() => {
				setShowGeolocationDialog(false);
			}}
		>
			<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
				Skru av/på visning og deling av egen posisjon i kart:
			</DialogTitle>
			<DialogContent>
				<Typography variant='body1' sx={{ marginBotton: 2 }}>
					OBS: Denne innstillingen gjelder kun for å vise denne
					enhetens posisjon i kartet, og kun for denne hendelsen.
					Eventuell deling av posisjon for andre hendelser gjøres fra
					menyen i dem. Vær OBS på at du kun kan dele posisjon fra en
					enhet pr. hendelse.
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<FormControlLabel
							control={
								<Switch
									checked={showOwnLocation}
									onChange={(evt) => {
										setShowOwnLocation(evt.target.checked);
										if (!evt.target.checked) {
											setShareOwnLocation(false);
										}
									}}
								/>
							}
							label='Vis min posisjon(Kun for deg)'
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControlLabel
							control={
								<Switch
									checked={shareOwnLocation}
									disabled={!showOwnLocation}
									onChange={(evt) => {
										setShareOwnLocation(evt.target.checked);
									}}
								/>
							}
							label='Del min posisjon'
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							variant='contained'
							color='primary'
							fullWidth
							onClick={handleSaveSettings}
						>
							Lagre
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							variant='contained'
							color='secondary'
							fullWidth
							onClick={() => {
								setShowGeolocationDialog(false);
							}}
						>
							Avbryt
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
