import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import IncidentContainer from '../Pages/Incident/IncidentContainer';
import NewIncident from '../Pages/NewIncident/NewIncident';
import SharedIncidentList from '../Pages/SharedIncidentList/SharedIncidentList';
import PageNotFound from '../Pages/PageNotFound/PageNotFound';
import Profile from '../Pages/Profile/Profile';
import OrgAdmin from '../Pages/OrgAdmin/OrgAdmin';
import GroupAdmin from '../Pages/GroupAdmin/GroupAdmin';
import LoginPhone from '../Pages/Login/LoginPhone';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Evaluation from '../Pages/Evaluation/Evaluation';

function PrivateRoute({ component: Component, ...rest }) {
	const { currentUser } = useAuth();
	return (
		<Route
			{...rest}
			render={(props) => {
				return currentUser ? (
					currentUser.displayName ? (
						<Component {...props} />
					) : (
						<Profile />
					)
				) : (
					<LoginPhone />
				);
			}}
		></Route>
	);
}

const PageRouter = () => {
	return (
		<Switch>
			<PrivateRoute path='/' exact component={Dashboard} />
			<PrivateRoute path='/hendelse/:id' component={IncidentContainer} />
			<PrivateRoute path='/ny-hendelse/' component={NewIncident} />
			<PrivateRoute
				path='/delte-hendelser'
				component={SharedIncidentList}
			/>
			<PrivateRoute path='/grupper' component={GroupAdmin} />
			<PrivateRoute path='/min-profil' component={Profile} />
			<PrivateRoute path='/admin-org' component={OrgAdmin} />
			<PrivateRoute path='/evaluering/:id' component={Evaluation} />
			<Route path='/innlogging' component={LoginPhone} />
			<Route component={PageNotFound} />
		</Switch>
	);
};

export default PageRouter;
