import {
	Avatar,
	Button,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';

export default function SingleGroup({ selectedGroup, setSelectedGroup }) {
	const [name, setName] = useState(selectedGroup.contact.name);
	const [email, setEmail] = useState(selectedGroup.contact.email);
	const [phone, setPhone] = useState(selectedGroup.contact.phone);
	return (
		<>
			<Grid
				container
				spacing={1}
				sx={{ display: 'flex', marginBottom: 2 }}
			>
				<Grid item>
					<Avatar variant='square' src={selectedGroup.logoUrl} />
				</Grid>
				<Grid item sx={{ flexGrow: 1 }}>
					<Typography variant='h4'>{selectedGroup.name}</Typography>
				</Grid>
				<Grid item>
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							setSelectedGroup();
						}}
					>
						Tilbake
					</Button>
				</Grid>
			</Grid>
			<Divider />
			<Typography variant='h6' sx={{ marginBottom: 1 }}>
				Kontaktinformasjon:
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label='Navn'
						value={name}
						onChange={(evt) => {
							setName(evt.target.value);
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						label='Email'
						value={email}
						type='email'
						onChange={(evt) => {
							setEmail(evt.target.value);
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						label='Telefonnummer'
						type='tel'
						value={phone}
						onChange={(evt) => {
							setPhone(evt.target.value);
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth color='primary' variant='contained'>
						Oppdater Kontaktinformasjon
					</Button>
				</Grid>
			</Grid>
			<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
			<Grid container spacing={2}>
				<Grid item>
					<Button variant='contained' color='info' size='small'>
						Legg til...
					</Button>
				</Grid>
				<Grid item>
					<Typography variant='h6'>Medlemmer:</Typography>
				</Grid>
			</Grid>

			<List>
				<ListItem>
					<ListItemButton>
						<ListItemAvatar>
							<Avatar />
						</ListItemAvatar>
						<ListItemText
							primary='Otto André Haaland'
							secondary='Rolle: ADMIN, Status: OK'
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</>
	);
}
