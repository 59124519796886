import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

export default function OrgAdmin() {
    return (
        <Container>
            <Card sx={{ marginTop: 1 }}>
                <CardHeader title='Dashboard for SQUARE' />
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card sx={{ backgroundColor: '#bfcce0' }}>
                                <CardHeader
                                    title='Kontaktinformasjon'
                                    sx={{
                                        backgroundColor: '#374254',
                                        color: 'white',
                                    }}
                                />
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant='outlined'
                                                fullWidth
                                                label='Organisasjonsnavn'
                                                value='SQUARE'
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant='outlined'
                                                fullWidth
                                                value='Otto André Haaland'
                                                label='Kontaktperson'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant='outlined'
                                                fullWidth
                                                value='otto.andre@haaland-multimedia.no'
                                                label='Email'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant='outlined'
                                                fullWidth
                                                label='Telefon'
                                                value='91362941'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                fullWidth
                                            >
                                                Lagre endringer
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                fullWidth
                                            >
                                                Nullstill endringer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card sx={{ backgroundColor: '#bfcce0' }}>
                                <CardHeader
                                    title='Brukere'
                                    action={
                                        <IconButton>
                                            <AddCircleOutlineIcon
                                                sx={{ color: 'white' }}
                                            />
                                        </IconButton>
                                    }
                                    sx={{
                                        backgroundColor: '#374254',
                                        color: 'white',
                                    }}
                                />
                                <CardContent>
                                    <List>
                                        <ListItem>
                                            <ListItemButton>
                                                <ListItemAvatar>
                                                    <Avatar>AA</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary='Otto André Haaland'
                                                    secondary={
                                                        <Typography variant='body1'>
                                                            Ble med: 05.11-21,
                                                            Hendelser: 5, Rolle:
                                                            Administrator
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemButton>
                                                <ListItemAvatar>
                                                    <Avatar>AA</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary='Otto André Haaland'
                                                    secondary={
                                                        <Typography variant='body1'>
                                                            Ble med: 05.11-21,
                                                            Hendelser: 5, Rolle:
                                                            Administrator
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemButton>
                                                <ListItemAvatar>
                                                    <Avatar>AA</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary='Otto André Haaland'
                                                    secondary={
                                                        <Typography variant='body1'>
                                                            Ble med: 05.11-21,
                                                            Hendelser: 5, Rolle:
                                                            Administrator
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemButton>
                                                <ListItemAvatar>
                                                    <Avatar>AA</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary='Otto André Haaland'
                                                    secondary={
                                                        <Typography variant='body1'>
                                                            Ble med: 05.11-21,
                                                            Hendelser: 5, Rolle:
                                                            Administrator
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card sx={{ backgroundColor: '#bfcce0' }}>
                                <CardHeader
                                    title='Siste hendelser'
                                    sx={{
                                        backgroundColor: '#374254',
                                        color: 'white',
                                    }}
                                />
                                <CardContent>
                                    <List>
                                        <ListItem>
                                            <ListItemButton>
                                                <ListItemAvatar>
                                                    <Avatar>AA</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary='Otto André Haaland'
                                                    secondary={
                                                        <Typography variant='body1'>
                                                            Ble med: 05.11-21,
                                                            Hendelser: 5, Rolle:
                                                            Administrator
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}
