import {
	Avatar,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	TextField,
	Typography,
} from '@mui/material';
import {
	Close as CloseIcon,
	SaveAlt as SaveAltIcon,
	Map as MapIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { useMenu } from '../../Context/MenuContext';
import { arrayUnion, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../Services/firebase';

export default function IncidentInfoDialog({
	incidentData,
	setChangeIncidentPositionMode,
	id,
}) {
	const { showIncidentInfoDialog, setShowIncidentInfoDialog } = useMenu();
	const { currentUser } = useAuth();
	const [description, setDescription] = useState(
		incidentData.editable.description
	);
	const [location, setLocation] = useState(incidentData.editable.location);
	const [allowSave, setAllowSave] = useState(false);

	const handleUpdateIncident = async () => {
		await updateDoc(doc(db, 'incidents', id), {
			'editable.location': location,
			'editable.description': description,
			changes: arrayUnion({
				changedById: currentUser.uid,
				changedByName: currentUser.displayName,
				changedAt: Timestamp.now(),
				oldLocation: incidentData.editable.location,
				newLocation: location,
				oldDescription: incidentData.editable.description,
				newDescription: description,
			}),
		});
		setShowIncidentInfoDialog(false);
	};
	return (
		<Dialog
			open={showIncidentInfoDialog}
			onClose={() => {
				setShowIncidentInfoDialog(false);
			}}
			maxWidth='md'
			fullWidth
		>
			<DialogTitle>Hendelsesinformasjon:</DialogTitle>
			<DialogContent>
				{'Opprettet av:'}
				<List>
					<ListItem>
						<ListItemAvatar sx={{ marginRight: 2 }}>
							<Avatar
								src={incidentData.authorImgUrl}
								sx={{ height: 60, width: 60 }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={incidentData.authorName}
							secondary={
								<Typography variant='body2'>
									Opprettet:{' '}
									{moment
										.unix(incidentData.created.seconds)
										.format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
								</Typography>
							}
						/>
					</ListItem>
				</List>
				<Divider />
				{incidentData.roles.admin.includes(currentUser.uid) ? (
					<>
						<TextField
							sx={{ marginBottom: 2 }}
							variant='outlined'
							fullWidth
							label='Lokasjon(fritekst)'
							value={location}
							onChange={(evt) => {
								setLocation(evt.target.value);
								if (
									evt.target.value !==
									incidentData.editable.location
								) {
									setAllowSave(true);
								}
							}}
						/>
						<TextField
							variant='outlined'
							fullWidth
							multiline
							rows={5}
							value={description}
							onChange={(evt) => {
								setDescription(evt.target.value);
								if (
									evt.target.value !==
									incidentData.description
								) {
									setAllowSave(true);
								}
							}}
							label='Beskrivelse'
						/>
						<Grid container spacing={1} sx={{ marginTop: 2 }}>
							<Grid item sm={4} xs={12}>
								<Button
									variant='contained'
									color='primary'
									fullWidth
									onClick={handleUpdateIncident}
									startIcon={<SaveAltIcon />}
									disabled={!allowSave}
								>
									Lagre
								</Button>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Button
									variant='contained'
									color='info'
									fullWidth
									onClick={() => {
										setChangeIncidentPositionMode(true);
										setShowIncidentInfoDialog(false);
									}}
									startIcon={<MapIcon />}
								>
									Endre posisjon
								</Button>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Button
									variant='contained'
									color='secondary'
									fullWidth
									onClick={() => {
										setShowIncidentInfoDialog(false);
									}}
									startIcon={<CloseIcon />}
								>
									Avbryt
								</Button>
							</Grid>
						</Grid>
					</>
				) : (
					<>
						<Typography variant='h6'>Beskrivelse:</Typography>
						<Typography variant='body1'>
							{incidentData.editable.description}
						</Typography>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
}
