import PageRouter from './Services/PageRouter';

import { BrowserRouter as Router } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from './Context/AuthContext';
import SideMenu from './Components/SideMenu';
import Header from './Components/Header';

function App() {
	const { currentUser } = useAuth();
	const [menuOpen, setMenuOpen] = useState(false);
	return (
		<Router>
			<Header
				currentUser={currentUser}
				menuOpen={menuOpen}
				setMenuOpen={setMenuOpen}
			/>
			{currentUser && (
				<SideMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			)}
			<PageRouter />
		</Router>
	);
}

export default App;
