import { addDoc, collection, Timestamp } from '@firebase/firestore';
import {
	Autocomplete,
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	Box,
	Typography,
	Grid,
	Button,
	Avatar,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';
import INSITUicons from './INSITUIcons.json';

export default function PoiSettingsDialog({
	setCreatePoiMode,
	tmpPoiPosition,
	setTmpPoiPosition,
}) {
	const { id } = useParams();
	const [saving, setSaving] = useState(false);
	const { currentUser } = useAuth();
	const [poiType, setPoiType] = useState(null);
	const [includeInChat, setIncludeInChat] = useState(false);
	const poiPos = tmpPoiPosition;
	const [info, setInfo] = useState('');

	const handleSaveFeature = async (markerUrl, message, position, type) => {
		setSaving(true);
		try {
			await addDoc(collection(db, 'incidents', id, 'markers'), {
				authorId: currentUser.uid,
				authorImgUrl: currentUser.photoURL,
				authorName: currentUser.displayName,
				created: Timestamp.now(),
				showInChat: includeInChat,
				markerUrl: markerUrl,
				message: message,
				position: position,
				type: type,
				dontShow: false,
			});
			setSaving(false);
			setTmpPoiPosition(null);
			setCreatePoiMode(false);
		} catch (err) {
			setSaving(false);
			console.log(err);
		}
	};
	return (
		<Dialog
			maxWidth='md'
			fullWidth
			open={Boolean(tmpPoiPosition)}
			onClose={() => {
				setTmpPoiPosition(null);
				setCreatePoiMode(false);
			}}
		>
			<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
				<Grid container>
					{poiType && (
						<Grid item sx={{ marginRight: 1 }}>
							<Avatar
								variant='square'
								src={poiType && poiType.iconUrl}
							/>
						</Grid>
					)}
					<Grid item>Informasjon om POI:</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Typography variant='body1'>
					Du lagrer POI på: {poiPos._lat + ', ' + poiPos._long}
				</Typography>
				<TextField
					multiline
					rows={4}
					label='Informasjon om POI'
					fullWidth
					value={info}
					onChange={(evt) => {
						setInfo(evt.target.value);
					}}
				/>
				<Autocomplete
					options={INSITUicons}
					getOptionLabel={(option) => option.name}
					value={poiType}
					onChange={(evt, value) => {
						setPoiType(value);
					}}
					renderOption={(props, option) => (
						<Box
							component='li'
							sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
							{...props}
						>
							<img
								loading='lazy'
								width='40'
								src={option.iconUrl}
								srcSet={option.iconUrl + ' 2x'}
								alt=''
							/>
							{option.name}
						</Box>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={{ marginTop: 1 }}
							label='Velg type POI'
							fullWidth
							variant='outlined'
						/>
					)}
				/>
				<FormGroup>
					<FormControlLabel
						disabled
						control={
							<Checkbox
								checked={includeInChat}
								disabled
								onChange={(evt) => {
									setIncludeInChat(evt.target.checked);
								}}
							/>
						}
						label='Inkluder i chat'
					/>
				</FormGroup>
				<Grid container spacing={1} sx={{ marginTop: 1 }}>
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='primary'
							fullWidth
							onClick={() => {
								if (poiType && poiPos) {
									handleSaveFeature(
										poiType.iconUrl || null,
										info,
										poiPos,
										poiType.name || null
									);
								}
							}}
							disabled={saving}
						>
							Lagre
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='secondary'
							fullWidth
							onClick={() => {
								setTmpPoiPosition(null);
								setCreatePoiMode(false);
							}}
						>
							Avbryt
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
