import {
	Alert,
	Avatar,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import moment from 'moment';
import { useMenu } from '../../Context/MenuContext';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import {
	addDoc,
	collection,
	onSnapshot,
	query,
	Timestamp,
	orderBy,
	limit,
	setDoc,
	doc,
	updateDoc,
	arrayUnion,
} from 'firebase/firestore';
import { db } from '../../Services/firebase';

export default function MemberDialog({ members, incident }) {
	const { currentUser } = useAuth();
	const { showMembersDialog, setShowMembersDialog } = useMenu();
	const [pendingList, setPendingList] = useState([]);
	const [activeMember, setActiveMember] = useState(null);
	const [lobbyMessages, setLobbyMessages] = useState([]);
	const [message, setMessage] = useState('');
	const [alert, setAlert] = useState(null);
	const [sending, setSending] = useState(false);
	const [role, setRole] = useState('guest');
	const handleSendMessage = async () => {
		setAlert(null);
		if (!message) {
			setAlert({
				severity: 'warning',
				message: 'Du må skrive noe i meldingen...',
			});
			return;
		}
		setSending(true);
		await addDoc(
			collection(
				db,
				'incidents',
				incident.id,
				'lobby',
				activeMember.userId,
				'messages'
			),
			{
				authorId: currentUser.uid,
				authorName: currentUser.displayName,
				authorImgUrl: currentUser.photoURL,
				created: Timestamp.now(),
				message: message,
			}
		).catch(() => {
			setAlert({
				severity: 'error',
				message: 'Noe gikk galt under ending...',
			});
			setSending(false);
		});
		setMessage('');
		setSending(false);
	};
	const handleApproveUser = async () => {
		let myRole = 'Gjest';
		if (role === 'member') {
			myRole = 'Medlem';
		}
		if (role === 'admin') {
			myRole = 'Administrator';
		}
		await setDoc(
			doc(db, 'incidents', incident.id, 'users', activeMember.id),
			{
				name: activeMember.userName,
				imgUrl: activeMember.userImgUrl,
				id: activeMember.id,
				joined: Timestamp.now(),
				role: myRole,
			}
		).catch((err) => {
			setAlert({
				severity: 'error',
				message: 'Noe gikk galt under godkjenning...',
			});
			return null;
		});
		if (role === 'guest') {
			await updateDoc(doc(db, 'incidents', incident.id), {
				'roles.guest': arrayUnion(activeMember.id),
			}).catch((err) => {
				setAlert({
					severity: 'error',
					message: 'Noe gikk galt under godkjenning...',
				});
				return null;
			});
		}
		if (role === 'member') {
			await updateDoc(doc(db, 'incidents', incident.id), {
				'roles.member': arrayUnion(activeMember.id),
			}).catch((err) => {
				setAlert({
					severity: 'error',
					message: 'Noe gikk galt under godkjenning...',
				});
				return null;
			});
		}
		if (role === 'admin') {
			await updateDoc(doc(db, 'incidents', incident.id), {
				'roles.admin': arrayUnion(activeMember.id),
			}).catch((err) => {
				setAlert({
					severity: 'error',
					message: 'Noe gikk galt under godkjenning...',
				});
				return null;
			});
		}
		setActiveMember(null);
	};
	const handleRejectUser = async () => {
		await updateDoc(
			doc(db, 'incidents', incident.id, 'lobby', activeMember.id),
			{
				rejected: Timestamp.now(),
			}
		).catch((err) => {
			setAlert({
				severity: 'error',
				message: 'Noe gikk galt under lagring',
			});
			return;
		});
		setActiveMember(null);
	};
	const handleChangeUser = async () => {};
	useEffect(() => {
		if (incident.roles.admin.includes(currentUser.uid)) {
			const q = query(collection(db, 'incidents', incident.id, 'lobby'));
			const unsub = onSnapshot(q, (querySnapshot) => {
				setPendingList(
					querySnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}))
				);
			});
			return () => {
				unsub();
			};
		}
	}, [incident, currentUser]);
	useEffect(() => {
		if (incident.roles.admin.includes(currentUser.uid) && activeMember) {
			const q = query(
				collection(
					db,
					'incidents',
					incident.id,
					'lobby',
					activeMember.userId,
					'messages'
				),
				orderBy('created', 'desc'),
				limit(10)
			);
			const unsub = onSnapshot(q, (querySnapshot) => {
				setLobbyMessages(
					querySnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}))
				);
			});
			return () => {
				unsub();
			};
		}
	}, [incident, currentUser, activeMember]);
	return (
		<Dialog
			open={showMembersDialog}
			onClose={() => {
				setShowMembersDialog(false);
			}}
			scroll='paper'
			maxWidth='md'
			fullWidth
		>
			<DialogTitle>
				{activeMember ? 'Aktivt medlem' : 'Medlemmer:'}
			</DialogTitle>
			<DialogContent>
				{alert && (
					<Alert
						sx={{ marginBottom: 2 }}
						onClose={() => {
							setAlert(null);
						}}
						severity={alert.severity}
					>
						{alert.message}
					</Alert>
				)}
				{activeMember &&
				incident.roles.admin.includes(currentUser.uid) ? (
					<>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={4}>
								<Button
									variant='contained'
									color='info'
									fullWidth
									onClick={() => {
										setActiveMember(null);
									}}
								>
									Tilbake
								</Button>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Button
									variant='contained'
									color='primary'
									fullWidth
									onClick={handleApproveUser}
								>
									Godkjenn
								</Button>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Button
									variant='contained'
									color='secondary'
									fullWidth
									onClick={handleRejectUser}
								>
									Avvis
								</Button>
							</Grid>
						</Grid>
						<FormControl fullWidth sx={{ marginTop: 2 }}>
							<InputLabel id='role-label'>Rolle</InputLabel>
							<Select
								labelId='role-label'
								label='Rolle'
								value={role}
								onChange={(evt) => {
									setRole(evt.target.value);
								}}
							>
								<MenuItem value={'guest'}>Gjest</MenuItem>
								<MenuItem value={'member'}>Medlem</MenuItem>
								<MenuItem value={'admin'}>Admin</MenuItem>
							</Select>
						</FormControl>
						<ListItem
							sx={{
								backgroundColor: 'lightgreen',
								marginTop: 1,
								marginBotom: 1,
							}}
						>
							<ListItemAvatar sx={{ marginRight: 2 }}>
								<Avatar
									src={activeMember.userImgUrl || ''}
									sx={{
										height: 60,
										width: 60,
									}}
								/>
							</ListItemAvatar>
							<ListItemText
								primary={activeMember.userName}
								secondary={
									<Typography variant='body2'>
										Ba om tilgang:{' '}
										{moment
											.unix(
												activeMember.requestTime.seconds
											)
											.format(
												'DD.MM-YYYY[, kl. ]HH:mm:ss'
											)}
									</Typography>
								}
							/>
						</ListItem>
						<Divider sx={{ marginBottom: 1, marginTop: 2 }} />
						<TextField
							variant='outlined'
							fullWidth
							label='Melding'
							value={message}
							onChange={(evt) => {
								setMessage(evt.target.value);
							}}
							onKeyUp={(evt) => {
								if (evt.key === 'Enter' && !sending) {
									handleSendMessage();
								}
							}}
							sx={{ marginBottom: 1 }}
						/>
						<Button
							variant='contained'
							color='primary'
							fullWidth
							onClick={handleSendMessage}
							disabled={sending}
						>
							Send melding
						</Button>
						<Divider sx={{ marginBottom: 2, marginTop: 1 }} />
						<List>
							{lobbyMessages.length > 0 &&
								lobbyMessages.map((message, index) => {
									return (
										<React.Fragment key={index}>
											<ListItem alignItems='flex-start'>
												<ListItemAvatar>
													<Avatar
														src={
															message.authorImgUrl
														}
														sx={{
															width: '80px',
															height: '80px',
															marginRight: 2,
														}}
													/>
												</ListItemAvatar>
												<ListItemText
													disableTypography
													primary={
														<Typography variant='body1'>
															<strong>
																{
																	message.authorName
																}
															</strong>
															<br />
															{moment
																.unix(
																	message
																		.created
																		.seconds
																)
																.format(
																	'DD.MM-YYYY[, kl. ]HH:mm:ss'
																)}
														</Typography>
													}
													secondary={
														<Paper
															elevation={10}
															sx={{
																backgroundColor:
																	'lightgrey',
																padding: 1,
															}}
														>
															<Typography variant='body2'>
																{
																	message.message
																}
															</Typography>
														</Paper>
													}
												/>
											</ListItem>
											<Divider />
										</React.Fragment>
									);
								})}
						</List>
					</>
				) : (
					<>
						<Typography variant='h6'>Godkjente:</Typography>
						<List>
							{members ? (
								members.map((member, index) => {
									if (member.joined) {
										return (
											<React.Fragment key={index}>
												<ListItem>
													<ListItemAvatar
														sx={{ marginRight: 2 }}
													>
														<Avatar
															src={
																member.imgUrl ||
																''
															}
															sx={{
																height: 60,
																width: 60,
															}}
														/>
													</ListItemAvatar>
													<ListItemText
														primary={
															member.name || ''
														}
														secondary={
															<Typography variant='body2'>
																Ble med i
																hendelsen:{' '}
																{member.joined &&
																	moment
																		.unix(
																			member
																				.joined
																				.seconds
																		)
																		.format(
																			'DD.MM-YYYY[, kl. ]HH:mm:ss'
																		)}
																<br />
																Rolle:{' '}
																{member.role &&
																	member.role}
															</Typography>
														}
													/>
												</ListItem>
												<Divider />
											</React.Fragment>
										);
									} else {
										return null;
									}
								})
							) : (
								<ListItem>
									<ListItemText primary='Ingen medlemmer funnet...' />
								</ListItem>
							)}
						</List>
						{incident.roles.admin.includes(currentUser.uid) && (
							<>
								<Typography variant='h6'>
									Avventende:
								</Typography>
								<List>
									{pendingList.length > 0 ? (
										pendingList.map((member, index) => {
											if (
												incident.roles.admin.includes(
													member.userId
												)
											) {
												return null;
											} else if (
												incident.roles.member.includes(
													member.userId
												)
											) {
												return null;
											} else if (
												incident.roles.guest.includes(
													member.userId
												)
											) {
												return null;
											} else if (member.rejected) {
												return null;
											} else {
												return (
													<React.Fragment key={index}>
														<ListItemButton
															onClick={() => {
																setActiveMember(
																	member
																);
															}}
														>
															<ListItemAvatar
																sx={{
																	marginRight: 2,
																}}
															>
																<Avatar
																	src={
																		member.userImgUrl ||
																		''
																	}
																	sx={{
																		height: 60,
																		width: 60,
																	}}
																/>
															</ListItemAvatar>
															<ListItemText
																primary={
																	member.userName ||
																	''
																}
																secondary={
																	<Typography variant='body2'>
																		Ba om å
																		bli med:{' '}
																		{member.requestTime &&
																			moment
																				.unix(
																					member
																						.requestTime
																						.seconds
																				)
																				.format(
																					'DD.MM-YYYY[, kl. ]HH:mm:ss'
																				)}
																		<br />
																		{member.group &&
																			member
																				.group
																				.groupName &&
																			'Gruppe: ' +
																				member
																					.group
																					.groupName}
																	</Typography>
																}
															/>
														</ListItemButton>
														<Divider />
													</React.Fragment>
												);
											}
										})
									) : (
										<ListItem>
											<ListItemText primary='Ingen medlemmer funnet...' />
										</ListItem>
									)}
								</List>
							</>
						)}
					</>
				)}
			</DialogContent>
		</Dialog>
	);
}
