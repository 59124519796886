import {
	Autocomplete,
	Button,
	Card,
	CardContent,
	CardHeader,
	TextField,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { useEffect } from 'react';
import { useState } from 'react';
import { db } from '../../Services/firebase';

export default function GroupInit({ handleRequestGroupAccess, processing }) {
	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [message, setMessage] = useState('');
	useEffect(() => {
		const q = query(collection(db, 'groups'));
		const unsub = onSnapshot(q, (querySnapshot) => {
			setGroups(
				querySnapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				}))
			);
			setLoading(false);
		});
		return unsub;
	}, []);
	return (
		<Card raised>
			<CardHeader title='Be om tilgang til gruppe:' />
			<CardContent>
				<Typography variant='body2'>
					Du er foreløpig ikke med i noen gruppe. Du kan fortsatt få
					gjestetilgang til en hendelse, men tilgangen er begrenset.
				</Typography>
				<Typography variant='body2'>
					For å be om tilgang til en gruppe, velger du en i listen
					under og starter søknadsprosessen. En gruppeadministrator
					vil deretter godkjenne eller avvise deg i gruppen.
				</Typography>
				<Autocomplete
					disabled={loading}
					options={groups}
					getOptionLabel={(option) => option.name}
					value={selectedGroup}
					onChange={(evt, value) => {
						setSelectedGroup(value);
					}}
					renderOption={(props, option) => (
						<Box
							component='li'
							sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
							{...props}
						>
							<img
								loading='lazy'
								width='40'
								src={option.logoUrl}
								srcSet={option.logoUrl + ' 2x'}
								alt=''
							/>
							{option.name}
						</Box>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={{ marginTop: 1 }}
							label={
								loading ? 'Laster grupper...' : 'Velg gruppe'
							}
							fullWidth
							variant='outlined'
						/>
					)}
				/>
				<TextField
					label='Melding til administrator'
					variant='outlined'
					fullWidth
					multiline
					rows={5}
					value={message}
					onChange={(evt) => {
						setMessage(evt.target.value);
					}}
					sx={{ marginTop: 2 }}
				/>
				<Button
					fullWidth
					variant='contained'
					color='primary'
					sx={{ marginTop: 2 }}
					disabled={loading || processing}
					onClick={() => {
						handleRequestGroupAccess(selectedGroup, message);
					}}
				>
					Be om tilgang
				</Button>
			</CardContent>
		</Card>
	);
}
