import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { AuthProvider } from './Context/AuthContext';
import { blueGrey, red } from '@mui/material/colors';
import { MenuProvider } from './Context/MenuContext';
import { StorageProvider } from './Context/StorageContext';

const theme = createTheme({
	palette: {
		background: {
			default: '#ECEFF1',
		},
		primary: {
			main: blueGrey[800],
		},
		secondary: {
			main: red[900],
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<MenuProvider>
					<StorageProvider>
						<CssBaseline />
						<App />
					</StorageProvider>
				</MenuProvider>
			</AuthProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
