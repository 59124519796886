import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	Grid,
	TextField,
	Button,
} from '@mui/material';
import {
	Save as SaveIcon,
	Close as CloseIcon,
	Delete as DeleteIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import { arrayUnion, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../Services/firebase';
import { useAuth } from '../../Context/AuthContext';

export default function PoiInfo({
	id,
	incident,
	activeFeature,
	setActiveFeature,
}) {
	const [info, setInfo] = useState(activeFeature.message || '');
	const { currentUser } = useAuth();
	const [allowSave, setAllowSave] = useState(false);
	const handleUpdateFeature = async () => {
		setAllowSave(false);
		let featureType = 'markers';
		if (activeFeature.drawOrder) {
			featureType = 'polygons';
		}
		if (activeFeature.isLine) {
			featureType = 'polylines';
		}
		if (activeFeature.photoUrl) {
			featureType = 'photos';
		}
		console.log(id);
		await updateDoc(
			doc(db, 'incidents', id, featureType, activeFeature.id),
			{
				message: info,
				changes: arrayUnion({
					changedById: currentUser.uid,
					changedByName: currentUser.displayName,
					timestamp: Timestamp.now(),
					changedFrom: activeFeature.message,
					changedTo: info,
				}),
			}
		).catch((err) => {
			console.log(err);
			setAllowSave(true);
		});
		setAllowSave(true);
		setActiveFeature(null);
	};
	const handleDeleteFeature = async () => {
		let featureType = 'markers';
		if (activeFeature.drawOrder) {
			featureType = 'polygons';
		}
		if (activeFeature.isLine) {
			featureType = 'polylines';
		}
		if (activeFeature.photoUrl) {
			featureType = 'photos';
		}
		await updateDoc(
			doc(db, 'incidents', id, featureType, activeFeature.id),
			{
				dontShow: true,
				changes: arrayUnion({
					changedById: currentUser.uid,
					changedByName: currentUser.displayName,
					timestamp: Timestamp.now(),
					change: 'Slettet',
				}),
			}
		);
		setActiveFeature(null);
	};
	return (
		<>
			{activeFeature && (
				<Dialog
					open={Boolean(activeFeature)}
					onClose={() => {
						setActiveFeature(null);
					}}
					fullWidth
					maxWidth='md'
				>
					{activeFeature.markerUrl && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							<Grid container>
								<Grid item sx={{ marginRight: 1 }}>
									<Avatar
										variant='square'
										src={activeFeature.markerUrl}
									/>
								</Grid>
								<Grid item>
									{activeFeature.type ||
										'Feil under lasting av POI type...'}
								</Grid>
							</Grid>
						</DialogTitle>
					)}
					{activeFeature.drawOrder && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							{activeFeature.drawOrder === 4 && 'Rødt '}
							{activeFeature.drawOrder === 3 && 'Gult '}
							{activeFeature.drawOrder === 2 && 'Grønt '}
							Polygon
						</DialogTitle>
					)}
					{activeFeature.isLine && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							{activeFeature.color === 'black' && 'Sort '}
							{activeFeature.color === 'red' && 'Rød '}
							{activeFeature.color === 'yellow' && 'Gul '}
							{activeFeature.color === 'green' && 'Grønn '}
							{activeFeature.color === 'blue' && 'Blå '}
							{activeFeature.color === 'orange' && 'Oransje '}
							{activeFeature.dashed ? 'stiplet ' : 'heltrukken '}
							Linje
						</DialogTitle>
					)}
					{activeFeature.photoUrl && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							Foto
						</DialogTitle>
					)}
					<DialogContent>
						<List>
							<ListItem>
								<ListItemAvatar sx={{ marginRight: 2 }}>
									<Avatar
										src={activeFeature.authorImgUrl || null}
										sx={{ height: 60, width: 60 }}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={
										'Opprettet av: ' +
										activeFeature.authorName
									}
									secondary={
										<Typography variant='body2'>
											{activeFeature.created &&
												moment
													.unix(
														activeFeature.created
															.seconds
													)
													.format(
														'DD.MM-YYYY[, kl. ]HH:mm:ss'
													)}
										</Typography>
									}
								/>
							</ListItem>
						</List>
						<Divider />
						{activeFeature.photoUrl && (
							<img
								src={activeFeature.photoUrl}
								width='100%'
								alt='Bilde'
							/>
						)}

						{currentUser.uid === activeFeature.authorId ||
						incident.roles.admin.includes(currentUser.uid) ? (
							<>
								<TextField
									variant='outlined'
									fullWidth
									multiline
									value={info}
									onChange={(evt) => {
										setInfo(evt.target.value);
										if (activeFeature.message !== info) {
											setAllowSave(true);
										}
									}}
									rows={5}
									label='Endre informasjon'
								/>
								<Grid
									container
									spacing={1}
									sx={{ marginTop: 1 }}
								>
									<Grid item xs={12} sm={4}>
										<Button
											variant='contained'
											color='primary'
											fullWidth
											startIcon={<SaveIcon />}
											onClick={handleUpdateFeature}
											disabled={!allowSave}
										>
											Lagre endringer
										</Button>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Button
											variant='contained'
											color='secondary'
											fullWidth
											startIcon={<CloseIcon />}
											onClick={() => {
												setActiveFeature(null);
											}}
										>
											Avbryt
										</Button>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Button
											variant='contained'
											color='warning'
											fullWidth
											startIcon={<DeleteIcon />}
											onClick={handleDeleteFeature}
										>
											Slett
										</Button>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<Typography variant='body1'>
									<strong>Info:</strong>
								</Typography>
								<Typography variant='body2'>
									{activeFeature.message}
								</Typography>
							</>
						)}
					</DialogContent>
				</Dialog>
			)}
		</>
	);
}
