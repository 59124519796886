import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	Container,
	Divider,
	Grid,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	TextField,
	Typography,
} from '@mui/material';
import {
	AddCircleOutline as AddCircleOutlineIcon,
	Search as SearchIcon,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { collection, onSnapshot, orderBy, query } from '@firebase/firestore';
import { db } from '../../Services/firebase';
import moment from 'moment';
import SingleGroup from './SingleGroup';

export default function GroupAdmin() {
	const [filterText, setFilterText] = useState('');

	const [groupList, setGroupList] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState(null);
	useEffect(() => {
		const q = query(collection(db, 'groups'), orderBy('name', 'asc'));
		const unsub = onSnapshot(q, (querySnapshot) => {
			setGroupList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return unsub;
	}, []);
	console.log(selectedGroup);
	return (
		<Container>
			<Card sx={{ marginTop: 1 }}>
				<CardHeader title='Administrasjon av grupper:' />
				<CardContent>
					{selectedGroup ? (
						<SingleGroup
							selectedGroup={selectedGroup}
							setSelectedGroup={setSelectedGroup}
						/>
					) : (
						<>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={2}>
									<Button
										variant='contained'
										color='primary'
										fullWidth
										size='large'
										sx={{ height: '100%' }}
										startIcon={<AddCircleOutlineIcon />}
									>
										Ny kunde
									</Button>{' '}
								</Grid>
								<Grid item xs={12} sm={10}>
									<TextField
										variant='outlined'
										fullWidth
										label='Filtrer'
										value={filterText}
										InputProps={{
											startAdornment: (
												<InputAdornment
													sx={{ marginRight: 2 }}
												>
													<SearchIcon />
												</InputAdornment>
											),
										}}
										onChange={(evt) => {
											setFilterText(evt.target.value);
										}}
									/>
								</Grid>
							</Grid>
							<List>
								{groupList.length === 0 && (
									<Typography variant='h6'>
										Ingen grupper funnet....
									</Typography>
								)}
								{groupList.map((group) => {
									if (filterText !== '') {
										if (
											!group.name
												.toLowerCase()
												.includes(
													filterText.toLowerCase()
												)
										) {
											return null;
										}
									}
									return (
										<>
											<ListItem key={group.id}>
												<ListItemButton
													onClick={(evt) => {
														setSelectedGroup(group);
													}}
												>
													<ListItemAvatar>
														<Avatar
															variant='square'
															sx={{
																height: '50px',
																width: '50px',
																marginRight: 2,
															}}
															src={group.logoUrl}
														/>
													</ListItemAvatar>
													<ListItemText
														primary={group.name}
														secondary={
															<Typography variant='body1'>
																Opprettet{' '}
																{moment
																	.unix(
																		group
																			.created
																			.seconds
																	)
																	.format(
																		'DD.MM-YYYY[, kl. ]HH:mm:ss'
																	)}
															</Typography>
														}
													/>
												</ListItemButton>
											</ListItem>
											<Divider />
										</>
									);
								})}
							</List>
						</>
					)}
				</CardContent>
			</Card>
		</Container>
	);
}
