import {
	Alert,
	Autocomplete,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { addDoc, collection, GeoPoint, Timestamp } from 'firebase/firestore';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { useState } from 'react';
import {
	MapContainer,
	Marker,
	TileLayer,
	useMap,
	useMapEvents,
} from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';

function MyComponent(props) {
	const map = useMap();
	useMapEvents({
		click: (e) => {
			props.handleMapClick(e);
		},
	});
	if (props.position) {
		map.flyTo({ lat: props.position._lat, lng: props.position._long }, 12);
	}
	return null;
}

export default function CreateNewIncident({ setShowNewIncident }) {
	const { currentUser, userData } = useAuth();
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState(null);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [location, setLocation] = useState(null);
	const [position, setPosition] = useState(null);
	const [locationList, setLocationList] = useState([]);
	const [isTraining, setIsTraining] = useState(false);
	const [isShared, setIsShared] = useState(false);
	const history = useHistory();
	const geoSearchProvider = new OpenStreetMapProvider({
		params: {
			'accept-language': 'no',
			countrycodes: 'no',
		},
	});
	const handleCreateIncident = async () => {
		setAlert(null);
		if (title.length < 3) {
			setAlert({
				severity: 'warning',
				message: 'Tittel må ha mer enn 3 tegn',
			});
			return;
		}
		if (description.length < 5) {
			setAlert({
				severity: 'warning',
				message: 'Beskrivelse må ha mer enn 5 tegn',
			});
			return;
		}
		if (!position) {
			setAlert({
				severity: 'warning',
				message: 'Alle hendelser må ha minst en posisjon i kart',
			});
			return;
		}
		if (!alert) {
			setLoading(true);
			let startLocation =
				'Lokasjon ikke beskrevet, se markering i kart...';
			if (location && location.label) {
				startLocation = location.label;
			}
			const docRef = await addDoc(collection(db, 'incidents'), {
				authorId: currentUser.uid,
				authorName: currentUser.displayName,
				authorPhone: currentUser.phoneNumber,
				authorImgUrl: currentUser.photoURL,
				created: Timestamp.now(),
				ended: null,
				groupId: userData.group.groupId,
				groupName: userData.group.groupName,
				groupLogoUrl: userData.group.groupLogoUrl,
				roles: {
					admin: [currentUser.uid],
					member: [],
					guest: [],
				},
				editable: {
					isTraining: isTraining,
					isShared: isShared,
					description: description,
					title: title,
					location: startLocation,
					position: position,
				},
			}).catch((err) => {
				setLoading(false);
				setAlert({
					severity: 'error',
					message: 'Noe gikk galt under opprettelse av hendelse...',
				});
				return;
			});
			setLoading(false);
			history.push('/hendelse/' + docRef.id);
			console.log(docRef);
		}
	};
	const handleMapClick = (e) => {
		setPosition(new GeoPoint(e.latlng.lat, e.latlng.lng));
	};
	const handleGeoSearch = async (evt) => {
		if (evt.target.value.length > 3) {
			setLocationList(
				await geoSearchProvider.search({ query: evt.target.value })
			);
		} else {
			setLocationList([]);
		}
	};
	return (
		<>
			<Typography variant='h4'>Ny hendelse:</Typography>
			<Typography variant='h6' sx={{ marginBottom: 2, marginTop: 2 }}>
				Lokasjon:
			</Typography>
			<Autocomplete
				freeSolo
				options={locationList}
				getOptionLabel={(option) => option.label}
				value={location}
				onChange={(evt, newValue) => {
					setLocation(newValue);
					setLocationList([]);
					if (newValue) {
						setPosition(new GeoPoint(newValue.y, newValue.x));
					} else {
						setPosition(null);
					}
				}}
				fullWidth
				sx={{ marginBottom: 2 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label='Lokasjon (sted/kommune)'
						onChange={(evt) => {
							handleGeoSearch(evt);
						}}
						variant='outlined'
					/>
				)}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant='body1'>
						Sett eller flytt markering i kart
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<MapContainer
						center={[58.5, 7.9]}
						zoom={8}
						scrollWheelZoom={true}
						style={{ height: '35vh' }}
					>
						<TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						/>
						{position && (
							<Marker
								position={[position._lat, position._long]}
							/>
						)}
						<MyComponent
							handleMapClick={handleMapClick}
							position={position}
						/>
					</MapContainer>
				</Grid>
			</Grid>
			<Divider sx={{ marginBottom: 2 }} />
			<Typography variant='h6' sx={{ marginBottom: 2, marginTop: 2 }}>
				Tittel og beskrivelse:
			</Typography>
			<TextField
				variant='outlined'
				fullWidth
				label='Hendelsestittel'
				value={title}
				onChange={(evt) => {
					setTitle(evt.target.value);
				}}
				sx={{ marginBottom: 2 }}
			/>
			<TextField
				variant='outlined'
				fullWidth
				multiline
				rows={5}
				value={description}
				onChange={(evt) => {
					setDescription(evt.target.value);
				}}
				label='Kort hendelsesbeskrivelse'
				sx={{ marginBottom: 2 }}
			/>
			<Divider sx={{ marginBottom: 2 }} />
			<Typography variant='h6' sx={{ marginBottom: 2, marginTop: 2 }}>
				Ekstra tilvalg:
			</Typography>
			<FormGroup>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<FormControlLabel
							control={
								<Checkbox
									checked={isTraining}
									onChange={(evt) => {
										setIsTraining(evt.target.checked);
									}}
								/>
							}
							label='Marker hendelse som øvelse'
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControlLabel
							control={
								<Checkbox
									checked={isShared}
									onChange={(evt) => {
										setIsShared(evt.target.checked);
									}}
								/>
							}
							label='Gjør hendelse synlig for andre'
						/>
					</Grid>
				</Grid>
			</FormGroup>
			<Divider sx={{ marginBottom: 2 }} />
			<Grid container spacing={1}>
				<Grid item xs={12} sm={6}>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						disabled={loading}
						onClick={handleCreateIncident}
					>
						Start hendelse
					</Button>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Button
						variant='contained'
						color='secondary'
						fullWidth
						onClick={() => {
							setShowNewIncident(false);
						}}
					>
						Avbryt
					</Button>
				</Grid>
			</Grid>
			{alert && (
				<Alert
					severity={alert.severity || 'info'}
					sx={{ marginTop: 2 }}
					onClose={() => {
						setAlert(null);
					}}
				>
					{alert.message}
				</Alert>
			)}
		</>
	);
}
