import {
	Alert,
	Avatar,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Container,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';
import GroupInit from './GroupInit';
import GroupPage from './GroupPage';
import GroupStatus from './GroupStatus';

export default function Dashboard() {
	const { currentUser, userData } = useAuth();
	const [loading, setLoading] = useState(true);
	const [alert, setAlert] = useState(null);
	const [processing, setProcessing] = useState(false);
	const handleRequestGroupAccess = async (selectedGroup, message) => {
		if (!selectedGroup) {
			setAlert({
				severity: 'warning',
				message: 'Du må velge en gruppe...',
			});
			return;
		}
		const docRef = doc(
			db,
			'groups',
			selectedGroup.id,
			'accessRequests',
			currentUser.uid
		);
		setProcessing(true);
		setDoc(docRef, {
			...selectedGroup,
			userData: {
				name: currentUser.displayName,
				userId: currentUser.uid,
				imgUrl: currentUser.photoURL,
				phone: currentUser.phoneNumber,
			},
			message: message,
			requested: Timestamp.now(),
		})
			.then(() => {
				setAlert({
					severity: 'success',
					message:
						'Din søknad er sendt til administrator. Laster status...',
				});
			})
			.catch((err) => {
				setAlert({
					severity: 'warning',
					message:
						'Noe gikk galt under sending av søknad. Prøv igjen eller kontakt support.',
				});
				setProcessing(false);
			});
	};
	useEffect(() => {
		if (userData) {
			setLoading(false);
		}
	}, [userData]);

	return (
		<Container>
			{loading ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Box sx={{ height: 200, marginTop: '20%' }}>
						<CircularProgress size={200} />
						<Typography variant='body1'>
							Finner brukerdata...
						</Typography>
					</Box>
				</Box>
			) : (
				<Card sx={{ marginTop: 1 }}>
					<CardHeader
						avatar={
							userData &&
							userData.group &&
							userData.group.status !== 'pending' && (
								<Avatar
									src={
										userData && userData.group
											? userData.group.groupLogoUrl
											: ''
									}
									variant='square'
								/>
							)
						}
						title={
							<Typography variant='h5'>
								Hei {currentUser.displayName}, og velkommen til{' '}
								{userData &&
								userData.group &&
								userData.group.status &&
								userData.group.status !== 'pending'
									? `gruppen ${userData.group.groupName}`
									: 'SQUARE'}
							</Typography>
						}
					/>
					<CardContent>
						{alert && (
							<Alert
								onClose={() => {
									setAlert(null);
								}}
								severity={alert.severity || 'info'}
							>
								{alert.message}
							</Alert>
						)}
						{userData && !userData.group ? (
							<GroupInit
								handleRequestGroupAccess={
									handleRequestGroupAccess
								}
								processing={processing}
							/>
						) : userData.group.status === 'medlem' ||
						  userData.group.status === 'admin' ? (
							<GroupPage userData={userData} />
						) : (
							<GroupStatus
								group={userData.group}
								setProcessing={setProcessing}
							/>
						)}
					</CardContent>
				</Card>
			)}
		</Container>
	);
}
