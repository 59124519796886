import {
	addDoc,
	collection,
	GeoPoint,
	onSnapshot,
	query,
	Timestamp,
	where,
} from '@firebase/firestore';
import {
	Alert,
	Autocomplete,
	Button,
	Card,
	CardContent,
	CardHeader,
	Container,
	Divider,
	Grid,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { useEffect, useState } from 'react';
import {
	MapContainer,
	Marker,
	TileLayer,
	useMap,
	useMapEvents,
} from 'react-leaflet';
import { useHistory } from 'react-router';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';

function MyComponent(props) {
	const map = useMap();
	useMapEvents({
		click: (e) => {
			props.handleMapClick(e);
		},
	});
	if (props.position) {
		map.flyTo({ lat: props.position._lat, lng: props.position._long }, 12);
	}
	return null;
}

export default function NewIncident() {
	const { currentUser } = useAuth();
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [location, setLocation] = useState(null);
	const [position, setPosition] = useState(null);
	const [locationList, setLocationList] = useState([]);
	const [currentGroups, setCurrentGroups] = useState([]);
	const [group, setGroup] = useState('');
	const [isTraining, setIsTraining] = useState(false);
	const [isShared, setIsShared] = useState(false);
	const history = useHistory();
	const geoSearchProvider = new OpenStreetMapProvider({
		params: {
			'accept-language': 'no',
			countrycodes: 'no',
		},
	});
	const handleMapClick = (e) => {
		setPosition(new GeoPoint(e.latlng.lat, e.latlng.lng));
	};
	const handleNext = () => {
		if (activeStep >= 2) {
			if (!loading) {
				handleSubmit();
			}
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const handleGeoSearch = async (evt) => {
		if (evt.target.value.length > 3) {
			setLocationList(
				await geoSearchProvider.search({ query: evt.target.value })
			);
		} else {
			setLocationList([]);
		}
	};
	const handleSubmit = async () => {
		setAlert(null);
		if (title.length < 3) {
			setAlert({
				severity: 'warning',
				msg: 'Tittel må ha mer enn 3 tegn',
			});
			return;
		}
		if (description.length < 5) {
			setAlert({
				severity: 'warning',
				msg: 'Beskrivelse må ha mer enn 5 tegn',
			});
			return;
		}
		if (!position) {
			setAlert({
				severity: 'warning',
				msg: 'Alle hendelser må ha minst en posisjon i kart',
			});
			return;
		}
		if (!group && group !== 0) {
			setAlert({
				severity: 'warning',
				msg: 'Du må velge gruppe som hendelse skal startes i',
			});
			return;
		}
		try {
			if (!alert) setLoading(true);
			let startLocation =
				'Lokasjon ikke beskrevet, se markering i kart...';
			if (location && location.label) {
				startLocation = location.label;
			}
			console.log('Try');
			const data = await addDoc(collection(db, 'incidents'), {
				authorId: currentUser.uid,
				authorName: currentUser.displayName,
				authorPhone: currentUser.phoneNumber,
				authorImgUrl: currentUser.photoURL,
				created: Timestamp.now(),
				description: description,
				title: title,
				ended: null,
				groupId: currentGroups[group].id,
				groupName: currentGroups[group].name,
				groupLogoUrl: currentGroups[group].logoUrl,
				location: startLocation,
				position: position,
				meta: {
					isTraining: isTraining,
					isShared: isShared,
				},
			}).catch((err) => {
				console.log(err);
				setLoading(false);
			});
			if (data.id) {
				setLoading(false);
				history.push(`/hendelse/${data.id}`);
			}
		} catch (err) {}
	};
	useEffect(() => {
		const q = query(
			collection(db, 'users', currentUser.uid, 'groupAccess'),
			where('status.accepted', '!=', null)
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setCurrentGroups(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [currentUser]);
	return (
		<>
			{currentGroups.length > 0 ? (
				<Container>
					<Card sx={{ marginTop: '50px' }}>
						<CardHeader title='Ny hendelse' />
						<CardContent>
							<Stepper
								activeStep={activeStep}
								sx={{ paddingLeft: 3, paddingRight: 3 }}
							>
								<Step key={0}>
									<StepLabel>Tittel</StepLabel>
								</Step>
								<Step key={1}>
									<StepLabel>Lokasjon</StepLabel>
								</Step>
								<Step key={2}>
									<StepLabel>Ekstra</StepLabel>
								</Step>
							</Stepper>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									pt: 2,
								}}
							>
								<Button
									color='inherit'
									disabled={activeStep === 0}
									onClick={handleBack}
									sx={{ mr: 1 }}
								>
									Tilbake
								</Button>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button onClick={handleNext}>
									{activeStep === 2
										? 'Start hendelse'
										: 'Neste'}
								</Button>
							</Box>
							<Divider />
							{alert && (
								<Alert
									severity={alert.severity}
									onClose={() => {
										setAlert(null);
									}}
									sx={{ marginTop: 2 }}
								>
									{alert.msg}
								</Alert>
							)}
							{activeStep === 0 && (
								<>
									<Typography
										variant='h4'
										sx={{ marginBottom: 2, marginTop: 2 }}
									>
										Tittel og beskrivelse:
									</Typography>
									<TextField
										variant='outlined'
										fullWidth
										label='Hendelsestittel'
										value={title}
										onChange={(evt) => {
											setTitle(evt.target.value);
										}}
										sx={{ marginBottom: 2 }}
									/>
									<FormControl
										fullWidth
										sx={{ marginBottom: 2 }}
									>
										<InputLabel id='groupSelectLabel'>
											Gruppe
										</InputLabel>
										<Select
											labelId='groupSelectLabel'
											value={group}
											label='Gruppe'
											onChange={(evt) => {
												setGroup(evt.target.value);
											}}
										>
											{currentGroups.map(
												(currentGroup, index) => {
													return (
														<MenuItem
															key={index}
															value={index}
														>
															{currentGroup.name}
														</MenuItem>
													);
												}
											)}
										</Select>
									</FormControl>
									<TextField
										variant='outlined'
										fullWidth
										multiline
										rows={5}
										value={description}
										onChange={(evt) => {
											setDescription(evt.target.value);
										}}
										label='Kort hendelsesbeskrivelse'
										sx={{ marginBottom: 2 }}
									/>
								</>
							)}
							{activeStep === 1 && (
								<>
									<Typography
										variant='h4'
										sx={{ marginBottom: 2, marginTop: 2 }}
									>
										Lokasjon:
									</Typography>
									<Autocomplete
										options={locationList}
										getOptionLabel={(option) =>
											option.label
										}
										value={location}
										onChange={(evt, newValue) => {
											setLocation(newValue);
											setLocationList([]);
											if (newValue) {
												setPosition(
													new GeoPoint(
														newValue.y,
														newValue.x
													)
												);
											} else {
												setPosition(null);
											}
										}}
										fullWidth
										sx={{ marginBottom: 2 }}
										renderInput={(params) => (
											<TextField
												{...params}
												label='Lokasjon (sted/kommune)'
												onChange={(evt) => {
													handleGeoSearch(evt);
												}}
												variant='outlined'
											/>
										)}
									/>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography variant='body1'>
												Sett eller flytt markering i
												kart
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<MapContainer
												center={[58.5, 7.9]}
												zoom={8}
												scrollWheelZoom={true}
												style={{ height: '35vh' }}
											>
												<TileLayer
													attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
													url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
												/>
												{position && (
													<Marker
														position={[
															position._lat,
															position._long,
														]}
													/>
												)}
												<MyComponent
													handleMapClick={
														handleMapClick
													}
													position={position}
												/>
											</MapContainer>
										</Grid>
									</Grid>
								</>
							)}
							{activeStep === 2 && (
								<>
									<Typography
										variant='h4'
										sx={{ marginBottom: 2, marginTop: 2 }}
									>
										Ekstra tilvalg:
									</Typography>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={isTraining}
													onChange={(evt) => {
														setIsTraining(
															evt.target.checked
														);
													}}
												/>
											}
											label='Marker hendelse som øvelse'
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={isShared}
													onChange={(evt) => {
														setIsShared(
															evt.target.checked
														);
													}}
												/>
											}
											label='Gjør hendelse synlig for andre'
										/>
									</FormGroup>
								</>
							)}
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									pt: 2,
								}}
							>
								<Button
									color='inherit'
									disabled={activeStep === 0}
									onClick={handleBack}
									sx={{ mr: 1 }}
								>
									Tilbake
								</Button>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button onClick={handleNext}>
									{activeStep === 2
										? 'Start hendelse'
										: 'Neste'}
								</Button>
							</Box>
						</CardContent>
					</Card>
				</Container>
			) : (
				<Container>
					<h1>Du har ikke tilgang til å opprette hendelse...</h1>
				</Container>
			)}
		</>
	);
}
