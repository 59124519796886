import { GeoPoint } from 'firebase/firestore';
import { useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';

export default function MapActions({
	handleMapClick,
	showMyLocation,
	setUserPos,
}) {
	const handleLocationFound = (evt) => {
		const latlng = evt.latlng;
		console.log(latlng);
		console.log(evt.accuracy);
		setUserPos({
			pos: new GeoPoint(latlng.lat, latlng.lng),
			acc: evt.accuracy,
		});
	};
	const map = useMapEvents({
		click: (e) => {
			handleMapClick(e);
		},
	});
	useEffect(() => {
		if (showMyLocation) {
			console.log('ran');
			map.locate({
				setView: false,
			});
			map.on('locationfound', handleLocationFound);
		} else {
			setUserPos(null);
		}
		return map.stopLocate();
	}, [showMyLocation]);
	return null;
}
