import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';

export default function CreateNewMessage({ setShowNewMessage }) {
	const { currentUser, userData } = useAuth();
	const [saving, setSaving] = useState(false);
	const [alert, setAlert] = useState(null);
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');
	const handleNewMessage = async () => {
		setAlert(null);
		if (!userData.group.groupId && userData.group.status === 'pending') {
			setAlert({
				severity: 'warning',
				message: 'Du har ikke tilgang til å sende melding...',
			});
			return;
		}
		if (title.length === 0) {
			setAlert({
				severity: 'warning',
				message: 'Tittel kan ikke være blank...',
			});
			return;
		}
		if (message.length === 0) {
			setAlert({
				severity: 'warning',
				message: 'Melding kan ikke være blank...',
			});
			return;
		}
		setSaving(true);
		await addDoc(
			collection(db, 'groups', userData.group.groupId, 'messages'),
			{
				title: title,
				message: message,
				authorId: currentUser.uid,
				authorName: currentUser.displayName,
				authorImgUrl: currentUser.photoURL,
				created: Timestamp.now(),
			}
		).catch(() => {
			setAlert({
				severity: 'error',
				message: 'Feil under lagring av melding...',
			});
		});
		setSaving(false);
		setMessage('');
		setTitle('');
		setAlert({ severity: 'success', message: 'Melding lagt til...' });
		setShowNewMessage(false);
	};
	return (
		<>
			{alert && (
				<Alert
					severity={alert.severity || 'info'}
					onClose={() => {
						setAlert(null);
					}}
				>
					{alert.message}
				</Alert>
			)}
			<Typography variant='h4'>Ny melding:</Typography>
			<TextField
				fullWidth
				variant='outlined'
				label='Tittel'
				value={title}
				onChange={(evt) => {
					setTitle(evt.target.value);
				}}
				sx={{ marginBottom: 1, marginTop: 1 }}
			/>
			<TextField
				fullWidth
				variant='outlined'
				label='Melding'
				multiline
				rows={5}
				value={message}
				onChange={(evt) => {
					setMessage(evt.target.value);
				}}
				sx={{ marginBottom: 1 }}
			/>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={6}>
					<Button
						fullWidth
						variant='contained'
						color='primary'
						onClick={handleNewMessage}
					>
						Lagre
					</Button>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Button
						fullWidth
						variant='contained'
						color='secondary'
						onClick={() => {
							setShowNewMessage(false);
						}}
					>
						Avbryt
					</Button>
				</Grid>
			</Grid>
		</>
	);
}
