import { addDoc, collection, Timestamp } from '@firebase/firestore';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
	Grid,
	Button,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Alert,
} from '@mui/material';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useAuth } from '../../Context/AuthContext';
import { db, storage } from '../../Services/firebase';

export default function PhotoSettingsDialog({
	setCreatePhotoMode,
	tmpPhotoPosition,
	setTmpPhotoPosition,
}) {
	const { id } = useParams();
	const [saving, setSaving] = useState(false);
	const { currentUser } = useAuth();
	const [alert, setAlert] = useState(null);
	const [includeInChat, setIncludeInChat] = useState(false);
	const photoPos = tmpPhotoPosition;
	const [photoUrl, setPhotoUrl] = useState('');
	const [tempPhoto, setTempPhoto] = useState(null);
	const [info, setInfo] = useState('');
	const storageRef = ref(storage, '/incident/' + id + '/' + Date.now());
	const handleSaveFeature = async (markerUrl, message, position, type) => {
		setSaving(true);
		try {
			if (!tempPhoto) {
				throw new Error({ error: 'Missing photo' });
			}
			const uploadTask = uploadBytesResumable(storageRef, tempPhoto);
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log('Upload is ' + progress + '% done');
				},
				(error) => {
					console.log(error);
					setAlert({
						severity: 'error',
						message: 'Feil under lagring av bilde...',
					});
					setSaving(false);
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then(
						async (downloadURL) => {
							console.log('File available at', downloadURL);
							await addDoc(
								collection(db, 'incidents', id, 'photos'),
								{
									authorId: currentUser.uid,
									authorImgUrl: currentUser.photoURL,
									authorName: currentUser.displayName,
									created: Timestamp.now(),
									showInChat: includeInChat,
									photoUrl: downloadURL,
									message: info,
									position: photoPos,
									dontShow: false,
								}
							);
							setSaving(false);
							setAlert({
								severity: 'success',
								message: 'Foto lagret til database...',
							});
							setTmpPhotoPosition(null);
							setCreatePhotoMode(false);
						}
					);
				}
			);
		} catch (err) {
			setSaving(false);
			setAlert({
				severity: 'error',
				message: 'Feil under lagring av bilde...',
			});
			console.log(err);
		}
	};
	return (
		<Dialog
			maxWidth='md'
			fullWidth
			open={Boolean(tmpPhotoPosition)}
			onClose={() => {
				setTmpPhotoPosition(null);
				setCreatePhotoMode(false);
			}}
		>
			<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
				<Grid container>
					<Grid item>Informasjon om foto:</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				{alert && (
					<Alert severity={alert.severity}>{alert.message}</Alert>
				)}
				<Typography variant='body1'>
					Du lagrer foto på: {photoPos._lat + ', ' + photoPos._long}
				</Typography>
				<Button
					variant='contained'
					fullWidth
					component='label'
					sx={{ marginBottom: 2, marginTop: 2 }}
				>
					Ta/velg bilde
					<input
						accept='image/*'
						hidden
						type='file'
						capture='environment'
						onChange={(evt) => {
							setPhotoUrl(
								URL.createObjectURL(evt.target.files[0])
							);
							setTempPhoto(evt.target.files[0]);
						}}
					/>
				</Button>

				<TextField
					multiline
					rows={4}
					label='Informasjon om foto'
					fullWidth
					value={info}
					onChange={(evt) => {
						setInfo(evt.target.value);
					}}
				/>
				<FormGroup>
					<FormControlLabel
						disabled
						control={
							<Checkbox
								checked={includeInChat}
								disabled
								onChange={(evt) => {
									setIncludeInChat(evt.target.checked);
								}}
							/>
						}
						label='Inkluder i chat'
					/>
				</FormGroup>
				<Grid container spacing={1} sx={{ marginTop: 1 }}>
					{photoUrl && (
						<Grid item xs={12}>
							<img src={photoUrl} width={'100%'} alt='Preview' />
						</Grid>
					)}
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='primary'
							fullWidth
							onClick={() => {
								if (photoPos) {
									handleSaveFeature();
								}
							}}
							disabled={saving}
						>
							Lagre
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='secondary'
							fullWidth
							onClick={() => {
								setTmpPhotoPosition(null);
								setCreatePhotoMode(false);
							}}
						>
							Avbryt
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
