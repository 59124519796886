import {
	Avatar,
	Button,
	Chip,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import {
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	Timestamp,
	updateDoc,
	where,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';
import {
	NotificationImportant as NotificationImportantIcon,
	Timer as TimerIcon,
	TimerOff as TimerOffIcon,
	Description as DescriptionIcon,
	Person as PersonIcon,
	LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';

export default function GroupEvaluationList({ setShowEvalMode }) {
	const { currentUser } = useAuth();
	const [pendingIncidentList, setPendingIncidentList] = useState([]);
	const [activeIncidentList, setActiveIncidentList] = useState([]);
	const [selectedIncident, setSelectedIncident] = useState(null);
	const [currentGroup, setCurrentGroup] = useState(null);
	const { userData } = useAuth();
	const history = useHistory();
	const incidentIcon = new L.Icon({
		iconUrl: 'https://squaremap.no/icons/mapIconsINSITU/1.1.3.svg',
		iconRetinaUrl: 'https://squaremap.no/icons/mapIconsINSITU/1.1.3.svg',
		iconSize: new L.Point(65, 65),
		iconAnchor: [25, 50],
		popupAnchor: [0, -50],
	});
	console.log(currentGroup);
	const handleStart = async () => {
		if (selectedIncident.evalState === 'pending') {
			await updateDoc(doc(db, 'incidents', selectedIncident.id), {
				evalState: 'active',
				evalValues: {
					started: Timestamp.now(),
					ended: null,
					startedById: currentUser.uid,
					startedByName: currentUser.displayName,
					startedByImgUrl: currentUser.photoURL,
				},
			});
		}
		history.push('/evaluering/' + selectedIncident.id);
	};
	useEffect(() => {
		const q = query(doc(db, 'groups', userData.group.groupId));
		const unsub = onSnapshot(q, (querySnapshot) => {
			setCurrentGroup(querySnapshot.data());
		});
		return () => {
			unsub();
		};
	}, [userData]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents'),
			where('evalState', '==', 'pending'),
			where('groupId', '==', userData.group.groupId),
			orderBy('ended', 'desc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setPendingIncidentList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [userData]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents'),
			where('evalState', '==', 'active'),
			where('groupId', '==', userData.group.groupId),
			orderBy('ended', 'desc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setActiveIncidentList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [userData]);
	return (
		<>
			{selectedIncident && (
				<Dialog
					open={Boolean(selectedIncident)}
					onClose={() => {
						setSelectedIncident(null);
					}}
					fullWidth
					maxWidth='md'
				>
					<DialogTitle>
						Hendelse: {selectedIncident.editable.title}
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={1}>
							<MapContainer
								style={{ width: '100%', height: '300px' }}
								center={[
									selectedIncident.editable.position._lat,
									selectedIncident.editable.position._long,
								]}
								zoom={13}
								scrollWheelZoom={true}
								zoomControl={false}
							>
								<TileLayer
									attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
									url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
								/>
								<Marker
									position={[
										selectedIncident.editable.position._lat,
										selectedIncident.editable.position
											._long,
									]}
									icon={incidentIcon}
								/>
							</MapContainer>
							<Grid item xs={12}>
								<ListItem sx={{ border: '1px solid black' }}>
									<ListItemIcon>
										<DescriptionIcon />
									</ListItemIcon>
									<ListItemText
										primary='Beskrivelse:'
										secondary={
											selectedIncident.editable
												.description
										}
									/>
								</ListItem>
							</Grid>
							<Grid item xs={12} sm={6}>
								<ListItem>
									<ListItemIcon>
										<TimerIcon />
									</ListItemIcon>
									<ListItemText
										primary='Hendelse opprettet'
										secondary={moment
											.unix(
												selectedIncident.created.seconds
											)
											.format(
												'DD.MM-YYYY[, kl. ]HH:mm:ss'
											)}
									/>
								</ListItem>
							</Grid>
							<Grid item xs={12} sm={6}>
								<ListItem>
									<ListItemIcon>
										<TimerOffIcon />
									</ListItemIcon>
									<ListItemText
										primary='Hendelse avsluttet'
										secondary={moment
											.unix(
												selectedIncident.ended.seconds
											)
											.format(
												'DD.MM-YYYY[, kl. ]HH:mm:ss'
											)}
									/>
								</ListItem>
							</Grid>
							<Grid item xs={12} sm={6}>
								<ListItem>
									<ListItemIcon>
										<PersonIcon />
									</ListItemIcon>
									<ListItemText
										primary='Hendelse opprettet av:'
										secondary={selectedIncident.authorName}
									/>
								</ListItem>
							</Grid>
							<Grid item xs={12} sm={6}>
								<ListItem>
									<ListItemIcon>
										<LocationOnIcon />
									</ListItemIcon>
									<ListItemText
										primary='Lokasjon'
										secondary={
											selectedIncident.editable.location
										}
									/>
								</ListItem>
							</Grid>
							{selectedIncident.evalState &&
								selectedIncident.evalState !== 'pending' && (
									<>
										<Grid item xs={12} sm={6}>
											<ListItem>
												<ListItemIcon>
													<TimerIcon />
												</ListItemIcon>
												<ListItemText
													primary='Evaluering startet:'
													secondary={moment
														.unix(
															selectedIncident
																.evalValues
																.started.seconds
														)
														.format(
															'DD.MM-YYYY[, kl. ]HH:mm:ss'
														)}
												/>
											</ListItem>
										</Grid>
										<Grid item xs={12} sm={6}>
											<ListItem>
												<ListItemIcon>
													<PersonIcon />
												</ListItemIcon>
												<ListItemText
													primary='Evaluering startet av:'
													secondary={
														selectedIncident
															.evalValues
															.startedByName
													}
												/>
											</ListItem>
										</Grid>
									</>
								)}
							{/*
							<Autocomplete
								options={memberList}
								getOptionLabel={(option) => option.label}
								value={member}
								onChange={(evt, newValue) => {
									setMember(newValue);
								}}
								fullWidth
								sx={{ marginBottom: 2 }}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Ansvarlig for hendelse'
										variant='outlined'
									/>
								)}
							/>
							<TextField
								label='Ansvarlig for evaluering'
								variant='outlined'
								fullWidth
							/>
								*/}
							<Grid item xs={12} sm={6}>
								<Button
									fullWidth
									variant='contained'
									color='primary'
									onClick={handleStart}
								>
									{selectedIncident.evalState === 'pending'
										? 'Start Evaluering'
										: 'Gå til evaluering'}
								</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button
									fullWidth
									variant='contained'
									color='secondary'
									onClick={() => {
										setSelectedIncident(null);
									}}
								>
									Avbryt
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
			<Button
				variant='contained'
				color='secondary'
				onClick={() => {
					setShowEvalMode(false);
				}}
			>
				Tilbake
			</Button>
			<Grid container spacing={1}>
				<Grid item xs={12} md={6}>
					<Typography variant='h6'>Klar for evaluering:</Typography>
					<List>
						{pendingIncidentList.length > 0 ? (
							pendingIncidentList.map((incident, index) => {
								return (
									<React.Fragment key={index}>
										<ListItemButton
											alignItems='flex-start'
											onClick={() => {
												setSelectedIncident(incident);
											}}
											sx={
												incident.editable.isTraining
													? {
															backgroundColor:
																'lightBlue',
													  }
													: {}
											}
										>
											<ListItemAvatar>
												<Avatar
													src={incident.authorImgUrl}
													sx={{
														width: '80px',
														height: '80px',
														marginRight: 2,
													}}
												/>
											</ListItemAvatar>
											<ListItemText
												disableTypography
												primary={
													<Typography variant='body1'>
														<strong>
															{
																incident
																	.editable
																	.title
															}
														</strong>
														<br />
														Opprettet av:{' '}
														{incident.authorName}
														,<br />{' '}
														{moment
															.unix(
																incident.created
																	.seconds
															)
															.format(
																'DD.MM-YYYY[, kl. ]HH:mm:ss'
															)}
													</Typography>
												}
												secondary={
													<>
														<Typography variant='body2'>
															{
																incident
																	.editable
																	.description
															}
														</Typography>
														{incident &&
															incident.editable &&
															incident.editable
																.isTraining && (
																<Chip
																	variant='outlined'
																	label='Øvelse'
																/>
															)}
													</>
												}
											/>
										</ListItemButton>
										<Divider />
									</React.Fragment>
								);
							})
						) : (
							<>
								<ListItem alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar
											sx={{
												width: '80px',
												height: '80px',
												marginRight: 2,
											}}
										>
											<NotificationImportantIcon
												sx={{
													width: '50px',
													height: '50px',
												}}
											/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										primary={
											<Typography variant='body1'>
												<strong>
													Ingen hendelser funnet
												</strong>
											</Typography>
										}
										secondary={
											<>
												<Typography variant='body2'>
													Det finnes ingen åpne
													hendelser tilhørende denne
													gruppen...
												</Typography>
											</>
										}
									/>
								</ListItem>
								<Divider />
							</>
						)}
					</List>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography variant='h6'>Under evaluering:</Typography>
					<List>
						{activeIncidentList.length > 0 ? (
							activeIncidentList.map((incident, index) => {
								return (
									<React.Fragment key={index}>
										<ListItemButton
											alignItems='flex-start'
											onClick={() => {
												setSelectedIncident(incident);
											}}
											sx={
												incident.editable.isTraining
													? {
															backgroundColor:
																'lightBlue',
													  }
													: {}
											}
										>
											<ListItemAvatar>
												<Avatar
													src={incident.authorImgUrl}
													sx={{
														width: '80px',
														height: '80px',
														marginRight: 2,
													}}
												/>
											</ListItemAvatar>
											<ListItemText
												disableTypography
												primary={
													<Typography variant='body1'>
														<strong>
															{
																incident
																	.editable
																	.title
															}
														</strong>
														<br />
														Opprettet av:{' '}
														{incident.authorName}
														,<br />{' '}
														{moment
															.unix(
																incident.created
																	.seconds
															)
															.format(
																'DD.MM-YYYY[, kl. ]HH:mm:ss'
															)}
													</Typography>
												}
												secondary={
													<>
														<Typography variant='body2'>
															{
																incident
																	.editable
																	.description
															}
														</Typography>
														{incident &&
															incident.editable &&
															incident.editable
																.isTraining && (
																<Chip
																	variant='outlined'
																	label='Øvelse'
																/>
															)}
													</>
												}
											/>
										</ListItemButton>
										<Divider />
									</React.Fragment>
								);
							})
						) : (
							<>
								<ListItem alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar
											sx={{
												width: '80px',
												height: '80px',
												marginRight: 2,
											}}
										>
											<NotificationImportantIcon
												sx={{
													width: '50px',
													height: '50px',
												}}
											/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										primary={
											<Typography variant='body1'>
												<strong>
													Ingen hendelser funnet
												</strong>
											</Typography>
										}
										secondary={
											<>
												<Typography variant='body2'>
													Det finnes ingen hendelser
													som er under evaluering...
												</Typography>
											</>
										}
									/>
								</ListItem>
								<Divider />
							</>
						)}
					</List>
				</Grid>
				<Grid item xs={12}>
					<Typography variant='h6'>Evalueringsrapporter:</Typography>
				</Grid>
				<Grid item xs={12}>
					<List>
						{currentGroup &&
							currentGroup.reports &&
							currentGroup.reports.map((report, index) => {
								return (
									<ListItemButton
										key={index}
										component='a'
										href={report.report}
										target='_blank'
									>
										<ListItemText primary={report.name} />
									</ListItemButton>
								);
							})}
					</List>
				</Grid>
			</Grid>
		</>
	);
}
