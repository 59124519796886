import {
	Avatar,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import {
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	Timestamp,
	updateDoc,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import {
	Edit as EditIcon,
	ThumbDown as ThumbDownIcon,
	ThumbUp as ThumbUpIcon,
} from '@mui/icons-material';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';
import { Box } from '@mui/system';

export default function GroupMembers({ setShowGroupMembers }) {
	const [loading, setLoading] = useState(true);
	const [memberList, setMemberList] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const { currentUser, userData } = useAuth();
	const [role, setRole] = useState('medlem');
	console.log(memberList);
	const handleUserRequest = async (result, memberId) => {
		await updateDoc(
			doc(
				db,
				'groups',
				userData.group.groupId,
				'accessRequests',
				memberId
			),
			{
				accessData: {
					handleId: currentUser.uid,
					handleName: currentUser.displayName,
					handleImgUrl: currentUser.photoURL,
					handleTime: Timestamp.now(),
					requestStatus:
						result === 'accepted' ? 'medlem' : 'rejected',
				},
			}
		);
	};
	const handleUpdateUserInfo = async () => {
		if (selectedUser && selectedUser.userData.userId) {
			await updateDoc(
				doc(
					db,
					'groups',
					userData.group.groupId,
					'accessRequests',
					selectedUser.userData.userId
				),
				{
					accessData: {
						handleId: currentUser.uid,
						handleName: currentUser.displayName,
						handleImgUrl: currentUser.photoURL,
						handleTime: Timestamp.now(),
						requestStatus: role,
					},
				}
			).catch((err) => {
				return null;
			});
			setSelectedUser(null);
		}
	};
	useEffect(() => {
		const q = query(
			collection(db, 'groups', userData.group.groupId, 'accessRequests'),
			orderBy('userData.name', 'asc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setMemberList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
			setLoading(false);
		});
		return () => {
			unsub();
		};
	}, [userData]);
	useEffect(() => {
		if (
			selectedUser &&
			selectedUser.accessData &&
			selectedUser.accessData.groupRole
		) {
			setRole(selectedUser.accessData.groupRole);
		}
	}, [selectedUser]);
	return (
		<>
			{selectedUser && (
				<Dialog
					open={Boolean(selectedUser)}
					maxWidth='md'
					fullWidth
					onClose={() => {
						setSelectedUser(null);
					}}
				>
					<DialogTitle>
						Bruker: {selectedUser.userData.name}
					</DialogTitle>
					<DialogContent>
						<ListItem alignItems='flex-start'>
							<ListItemAvatar>
								<Avatar
									src={selectedUser.userData.imgUrl}
									sx={{
										width: '80px',
										height: '80px',
										marginRight: 2,
									}}
								/>
							</ListItemAvatar>
							<ListItemText
								disableTypography
								primary={
									<Typography variant='body1'>
										<strong>
											{selectedUser.userData.name}
										</strong>
									</Typography>
								}
								secondary={
									<>
										<Typography variant='body2'>
											<strong>Tlf:</strong>{' '}
											{selectedUser.userData.phone}
										</Typography>
										<Typography variant='body2'>
											<strong>Nåværende rolle: </strong>
											{
												selectedUser.accessData
													.requestStatus
											}
										</Typography>
									</>
								}
							/>
						</ListItem>
						<FormControl fullWidth sx={{ marginTop: 2 }}>
							<InputLabel id='role-select-label'>
								Endre rolle
							</InputLabel>
							<Select
								labelId='role-selct-label'
								label='Endre rolle'
								value={role}
								onChange={(evt) => {
									setRole(evt.target.value);
								}}
							>
								<MenuItem value={'medlem'}>Medlem</MenuItem>
								<MenuItem value={'admin'}>
									Administrator
								</MenuItem>
							</Select>
						</FormControl>
						<Grid container spacing={1} sx={{ marginTop: 2 }}>
							<Grid item xs={12} sm={6}>
								<Button
									variant='contained'
									color='primary'
									fullWidth
									disabled={
										selectedUser.accessData
											.requestStatus === role
									}
									onClick={handleUpdateUserInfo}
								>
									Lagre
								</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button
									variant='contained'
									color='secondary'
									fullWidth
									onClick={() => {
										setSelectedUser(null);
									}}
								>
									Avbryt
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
			{loading ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Box sx={{ height: 200, marginTop: '20%' }}>
						<CircularProgress size={200} />
						<Typography variant='body1'>
							Finner brukerdata...
						</Typography>
					</Box>
				</Box>
			) : (
				<Grid container spacing={1}>
					<Grid item xs={12} sm={2}>
						<Button
							variant='contained'
							color='secondary'
							fullWidth
							onClick={() => {
								setShowGroupMembers(false);
							}}
						>
							Tilbake
						</Button>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography variant='h4'>Gruppemedlemmer:</Typography>
					</Grid>
					<Grid item xs={12}>
						<List>
							{memberList.length > 0 ? (
								memberList.map((member, index) => {
									if (
										member.accessData &&
										(member.accessData.requestStatus ===
											'medlem' ||
											member.accessData.requestStatus ===
												'admin')
									) {
										return (
											<React.Fragment key={index}>
												<ListItem
													alignItems='flex-start'
													secondaryAction={
														userData.group
															.status ===
															'admin' &&
														currentUser.uid !==
															member.id && (
															<IconButton
																edge='end'
																aria-label='Rediger'
																onClick={() => {
																	setSelectedUser(
																		member
																	);
																}}
															>
																<EditIcon />
															</IconButton>
														)
													}
												>
													<ListItemAvatar>
														<Avatar
															src={
																member.userData
																	.imgUrl
															}
															sx={{
																width: '80px',
																height: '80px',
																marginRight: 2,
															}}
														/>
													</ListItemAvatar>
													<ListItemText
														disableTypography
														primary={
															<Typography variant='body1'>
																<strong>
																	{
																		member
																			.userData
																			.name
																	}
																</strong>
															</Typography>
														}
														secondary={
															<>
																<Typography variant='body2'>
																	<strong>
																		Tlf:
																	</strong>{' '}
																	{
																		member
																			.userData
																			.phone
																	}
																</Typography>
																<Typography variant='body2'>
																	<strong>
																		Rolle:{' '}
																	</strong>
																	{
																		member
																			.accessData
																			.requestStatus
																	}
																</Typography>
															</>
														}
													/>
												</ListItem>
												<Divider />
											</React.Fragment>
										);
									} else {
										return null;
									}
								})
							) : (
								<ListItemButton alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar
											src=''
											sx={{
												width: '80px',
												height: '80px',
												marginRight: 2,
											}}
										/>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										primary={
											<Typography variant='body1'>
												<strong>
													Kunne ikke finne noen
													brukere
												</strong>
											</Typography>
										}
									/>
								</ListItemButton>
							)}
						</List>
					</Grid>
					{userData.group.status === 'admin' && (
						<>
							<Grid item xs={12} sm={10}>
								<Typography variant='h4'>
									Forespørsler:
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<List>
									{memberList.length > 0 ? (
										memberList.map((member, index) => {
											if (!member.accessData) {
												return (
													<React.Fragment key={index}>
														<ListItem
															alignItems='flex-start'
															secondaryAction={
																userData.group
																	.status ===
																	'admin' && (
																	<>
																		<IconButton
																			sx={{
																				marginRight: 2,
																			}}
																			edge='end'
																			aria-label='Godta'
																			color='primary'
																			onClick={() => {
																				handleUserRequest(
																					'accepted',
																					member.id
																				);
																			}}
																		>
																			<ThumbUpIcon />
																		</IconButton>
																		<IconButton
																			edge='end'
																			aria-label='Avvis'
																			color='secondary'
																			onClick={() => {
																				handleUserRequest(
																					'rejected',
																					member.id
																				);
																			}}
																		>
																			<ThumbDownIcon />
																		</IconButton>
																	</>
																)
															}
														>
															<ListItemAvatar>
																<Avatar
																	src={
																		member
																			.userData
																			.imgUrl
																	}
																	sx={{
																		width: '80px',
																		height: '80px',
																		marginRight: 2,
																	}}
																/>
															</ListItemAvatar>
															<ListItemText
																disableTypography
																primary={
																	<Typography variant='body1'>
																		<strong>
																			{
																				member
																					.userData
																					.name
																			}
																		</strong>
																	</Typography>
																}
																secondary={
																	<>
																		<Typography variant='body2'>
																			Telefonnr:
																			{
																				member
																					.userData
																					.phone
																			}
																		</Typography>
																		<Typography variant='body2'>
																			<strong>
																				Melding:
																			</strong>
																			<br />
																			{
																				member.message
																			}
																		</Typography>
																	</>
																}
															/>
														</ListItem>
														<Divider />
													</React.Fragment>
												);
											} else {
												return null;
											}
										})
									) : (
										<ListItemButton alignItems='flex-start'>
											<ListItemAvatar>
												<Avatar
													src=''
													sx={{
														width: '80px',
														height: '80px',
														marginRight: 2,
													}}
												/>
											</ListItemAvatar>
											<ListItemText
												disableTypography
												primary={
													<Typography variant='body1'>
														<strong>
															Kunne ikke finne
															noen brukere
														</strong>
													</Typography>
												}
											/>
										</ListItemButton>
									)}
								</List>
							</Grid>
						</>
					)}
				</Grid>
			)}
		</>
	);
}
