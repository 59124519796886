import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../Services/firebase';
import {
	onAuthStateChanged,
	signInWithPhoneNumber,
	signOut,
	updateProfile,
} from '@firebase/auth';
import { doc, onSnapshot } from '@firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [userData, setUserData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showIncidentInfo, setShowIncidentInfo] = useState(false);

	const loginPhone = (phone, appVerifier) => {
		return signInWithPhoneNumber(auth, phone, appVerifier);
	};
	const logout = () => {
		return signOut(auth);
	};
	const updatePhoto = (photoUrl) => {
		return updateProfile(currentUser, {
			photoURL: photoUrl,
		});
	};
	const updateDisplayName = (displayName) => {
		return updateProfile(currentUser, {
			displayName: displayName,
		});
	};
	useEffect(() => {
		if (!currentUser) {
			return;
		}
		const unsub = onSnapshot(doc(db, 'users', currentUser.uid), (doc) => {
			setUserData(doc.data());
		});
		return unsub;
	}, [currentUser]);
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(
			auth,
			(user) => {
				setCurrentUser(user);
				setLoading(false);
			},
			(error) => {
				console.log(error);
				setLoading(false);
			}
		);
		return unsubscribe;
	}, []);

	const value = {
		currentUser,
		loginPhone,
		logout,
		updatePhoto,
		updateDisplayName,
		showIncidentInfo,
		setShowIncidentInfo,
		userData,
	};
	return (
		<AuthContext.Provider value={value}>
			{loading ? (
				<Container>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box sx={{ height: 200, marginTop: '20%' }}>
							<CircularProgress size={200} />
							<Typography variant='body1'>
								Finner brukerdata...
							</Typography>
						</Box>
					</Box>
				</Container>
			) : (
				children
			)}
		</AuthContext.Provider>
	);
}
