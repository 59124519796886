import { createContext, useContext } from 'react';

const StorageContext = createContext();

export function useStorage() {
	return useContext(StorageContext);
}

export function StorageProvider({ children }) {
	const cleanupLocalStorage = (incidentList) => {
		const localKeys = Object.keys(localStorage);
		if (incidentList.length > 0) {
			localKeys.forEach((key) => {
				let hasHit = false;
				if (key === 'showSelf') {
					return null;
				}
				incidentList.forEach((incident) => {
					if (key === incident.id) {
						hasHit = true;
						return null;
					}
				});
				if (!hasHit) {
					localStorage.removeItem(key);
				}
			});
		}
	};

	const value = { cleanupLocalStorage };
	return (
		<StorageContext.Provider value={value}>
			{children}
		</StorageContext.Provider>
	);
}
