import {
	Avatar,
	Chip,
	FormControlLabel,
	Grid,
	ListItem,
	ListItemText,
	Checkbox,
} from '@mui/material';
import moment from 'moment';
import { useAuth } from '../../Context/AuthContext';

export default function ChatMsg({
	chatMsg,
	incident,
	handleSetImportant,
	handleSetMediaFriendly,
	handleSetVisibility,
}) {
	const { currentUser } = useAuth();
	return (
		<>
			<ListItem>
				{currentUser.uid !== chatMsg.authorId ? (
					<Grid
						container
						spacing={1}
						sx={{
							width: '90%',
							paddingBottom: 1,
							background: 'lightgrey',
							border: 'solid black 2px',
							borderRadius: '20px 10px 10px 2px',
						}}
					>
						<Grid item>
							<Avatar src={chatMsg.authorImgUrl || null} />
						</Grid>
						<Grid item>
							<Grid container>
								<Grid item xs={12}>
									<ListItemText
										align='left'
										primary={chatMsg.message}
									/>
								</Grid>
								<Grid item xs={12}>
									<ListItemText
										align='left'
										primary={
											chatMsg.authorName +
											', ' +
											moment
												.unix(chatMsg.created.seconds)
												.format(
													'DD.MM-YYYY[, kl. ]HH:mm:ss'
												)
										}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							{incident.roles.admin.includes(currentUser.uid) ? (
								<>
									<FormControlLabel
										sx={{ marginLeft: 1, marginRight: 1 }}
										label='Viktig'
										control={
											<Checkbox
												checked={chatMsg.isImportant}
												onChange={(evt) => {
													handleSetImportant(
														chatMsg.id,
														evt.target.checked,
														chatMsg.isImportant
													);
												}}
											/>
										}
									/>
									<FormControlLabel
										sx={{ marginLeft: 1, marginRight: 1 }}
										label='Medievennlig'
										control={
											<Checkbox
												checked={
													chatMsg.isMediaFriendly
												}
												onChange={(evt) => {
													handleSetMediaFriendly(
														chatMsg.id,
														evt.target.checked,
														chatMsg.isMediaFriendly
													);
												}}
											/>
										}
									/>
									<FormControlLabel
										sx={{ marginLeft: 1, marginRight: 1 }}
										label='Skjult'
										control={
											<Checkbox
												checked={chatMsg.isHidden}
												onChange={(evt) => {
													handleSetVisibility(
														chatMsg.id,
														evt.target.checked,
														chatMsg.isHidden
													);
												}}
											/>
										}
									/>
								</>
							) : (
								<>
									{chatMsg.isImportant && (
										<Chip
											variant='filled'
											label='Viktig'
											color='warning'
											sx={{ marginRight: 1 }}
										/>
									)}
									{chatMsg.isMediaFriendly && (
										<Chip
											variant='filled'
											label='Medievennlig'
											color='warning'
											sx={{ marginRight: 1 }}
										/>
									)}
									{chatMsg.isHidden && (
										<Chip
											variant='filled'
											label='Skjult'
											color='warning'
											sx={{ marginRight: 1 }}
										/>
									)}
								</>
							)}
						</Grid>
					</Grid>
				) : (
					<>
						<Grid
							container
							spacing={1}
							sx={{
								marginLeft: '20%',
								paddingBottom: 1,
								paddingRight: 1,
								background: '#366fff',
								color: 'white',
								width: '90%',
								border: 'solid black 2px',
								borderRadius: '10px 20px 2px 10px',
							}}
						>
							<Grid item xs={12}>
								<ListItemText
									align='left'
									primary={chatMsg.message}
								/>
							</Grid>
							<Grid item xs={12}>
								<ListItemText
									align='left'
									primary={
										'Deg , ' +
										moment
											.unix(chatMsg.created.seconds)
											.format(
												'DD.MM-YYYY[, kl. ]HH:mm:ss'
											)
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									sx={{ marginLeft: 1, marginRight: 1 }}
									label='Viktig'
									control={
										<Checkbox
											checked={chatMsg.isImportant}
											onChange={(evt) => {
												handleSetImportant(
													chatMsg.id,
													evt.target.checked,
													chatMsg.isImportant
												);
											}}
										/>
									}
								/>
								<FormControlLabel
									sx={{ marginLeft: 1, marginRight: 1 }}
									label='Medievennlig'
									control={
										<Checkbox
											checked={chatMsg.isMediaFriendly}
											onChange={(evt) => {
												handleSetMediaFriendly(
													chatMsg.id,
													evt.target.checked,
													chatMsg.isMediaFriendly
												);
											}}
										/>
									}
								/>
								<FormControlLabel
									sx={{ marginLeft: 1, marginRight: 1 }}
									label='Skjult'
									control={
										<Checkbox
											checked={chatMsg.isHidden}
											onChange={(evt) => {
												handleSetVisibility(
													chatMsg.id,
													evt.target.checked,
													chatMsg.isHidden
												);
											}}
										/>
									}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</ListItem>
		</>
	);
}
