import {
	Avatar,
	Button,
	Container,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	LinearProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	Select,
	Slider,
	TextField,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import {
	LayerGroup,
	LayersControl,
	MapContainer,
	Marker,
	Polygon,
	Polyline,
	TileLayer,
	WMSTileLayer,
} from 'react-leaflet';
import {
	PlayArrow as PlayArrowIcon,
	Pause as PauseIcon,
	LastPage as LastPageIcon,
	FirstPage as FirstPageIcon,
	ZoomIn as ZoomInIcon,
	Plagiarism as PlagiarismIcon,
	Info as InfoIcon,
} from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
	arrayUnion,
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	Timestamp,
	updateDoc,
} from 'firebase/firestore';
import { db, functions } from '../../Services/firebase';
import { useParams } from 'react-router-dom';
import L from 'leaflet';
import { useAuth } from '../../Context/AuthContext';
import { useHistory } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';

export default function Evaluation() {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [alert, setAlert] = useState(null);
	const { id } = useParams();
	const { currentUser } = useAuth();
	const [incidentData, setIncidentData] = useState(null);
	const [markerList, setMarkerList] = useState([]);
	const [polygonList, setPolygonList] = useState([]);
	const [polyLineList, setPolyLineList] = useState([]);
	const [photoList, setPhotoList] = useState([]);
	const [messageList, setMessageList] = useState([]);
	const [playbackSpeed, setPlaybackSpeed] = useState(1);
	const [isPlaying, setIsPlaying] = useState(false);
	const [sliderState, setSliderState] = useState(0);
	const [sliderMarks, setSliderMarks] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [selectedItem, setSelectedItem] = useState(null);
	const [evalNote, setEvalNote] = useState('');
	const [savingEvalNote, setSavingEvalNote] = useState(false);

	const [evalIndex, setEvalIndex] = useState(0);
	const [showEvalDialog, setShowEvalDialog] = useState(true);
	const [eval1, setEval1] = useState('');
	const [eval2, setEval2] = useState('');
	const [eval3, setEval3] = useState('');
	const [eval4_1, setEval4_1] = useState('');
	const [eval4_2, setEval4_2] = useState('');
	const [eval5_1, setEval5_1] = useState('');
	const [eval5_2, setEval5_2] = useState('');
	const [eval6_1, setEval6_1] = useState('');
	const [eval6_2, setEval6_2] = useState('');
	const [eval7_1, setEval7_1] = useState('');
	const [eval7_2, setEval7_2] = useState('');
	const [eval8_1, setEval8_1] = useState('');
	const [eval8_2, setEval8_2] = useState('');
	const [eval9_1, setEval9_1] = useState('');
	const [eval9_2, setEval9_2] = useState('');
	const [eval10_1, setEval10_1] = useState('');
	const [eval10_2, setEval10_2] = useState('');
	const [eval22_1, setEval22_1] = useState('');
	const [eval22_2, setEval22_2] = useState('');
	const [eval11_1, setEval11_1] = useState('');
	const [eval11_2, setEval11_2] = useState('');
	const [eval11_3, setEval11_3] = useState('');
	const [eval12, setEval12] = useState('');
	const [eval13, setEval13] = useState('');
	const [eval14, setEval14] = useState('');
	const [eval15, setEval15] = useState('');
	const [eval16, setEval16] = useState('');
	const [eval17, setEval17] = useState('');
	const [eval18, setEval18] = useState('');
	const [eval19, setEval19] = useState('');
	const [eval20, setEval20] = useState('');
	const [eval21, setEval21] = useState('');
	const [showEndDialog, setShowEndDialog] = useState(false);

	const incidentIcon = new L.Icon({
		iconUrl: 'https://squaremap.no/icons/mapIconsINSITU/1.1.3.svg',
		iconRetinaUrl: 'https://squaremap.no/icons/mapIconsINSITU/1.1.3.svg',
		iconSize: new L.Point(65, 65),
		iconAnchor: [25, 50],
		popupAnchor: [0, -50],
	});
	const handleTogglePlayback = () => {
		setIsPlaying(!isPlaying);
	};
	const handlePrevEvent = () => {
		if (sliderMarks[currentIndex]) {
			if (sliderMarks[currentIndex].created.seconds === sliderState) {
				if (sliderMarks[currentIndex - 1]) {
					setSliderState(
						sliderMarks[currentIndex - 1].created.seconds
					);
					setCurrentIndex(currentIndex - 1);
				} else {
					setSliderState(incidentData.created.seconds);
				}
			} else {
				setSliderState(sliderMarks[currentIndex].created.seconds);
			}
		} else {
			setSliderState(incidentData.created.seconds);
		}
	};
	const handleNextEvent = () => {
		if (currentIndex === -1) {
			console.log('under min, hopper frem...');
			setSliderState(sliderMarks[0].created.seconds);
		} else {
			if (sliderMarks[currentIndex + 1]) {
				console.log(sliderMarks[currentIndex].created.seconds);
				console.log(sliderState);
				if (sliderMarks[currentIndex].created.seconds === sliderState) {
					if (sliderMarks[currentIndex + 1]) {
						setSliderState(
							sliderMarks[currentIndex + 1].created.seconds
						);
						setCurrentIndex(currentIndex + 1);
					} else {
						setSliderState(incidentData.ended.seconds);
					}
				} else {
					setSliderState(
						sliderMarks[currentIndex + 1].created.seconds
					);
				}
			} else {
				setSliderState(incidentData.ended.seconds);
			}
		}
	};
	const handleSaveEvalNote = async () => {
		//Tempcheck type
		setSavingEvalNote(true);
		let type = null;
		if (selectedItem.markerUrl) {
			type = 'markers';
		}
		if (selectedItem.changes) {
			type = 'messages';
		}
		if (selectedItem.photoUrl) {
			type = 'photos';
		}
		if (selectedItem.drawOrder) {
			type = 'polygons';
		}
		if (selectedItem.isLine) {
			type = 'polylines';
		}
		if (!type) {
			setAlert({
				severity: 'warning',
				message: 'Finner ikke POI i database...',
			});
			setSavingEvalNote(false);
			return null;
		}
		await updateDoc(doc(db, 'incidents', id, type, selectedItem.id), {
			evalNotes: arrayUnion({
				note: evalNote,
				created: Timestamp.now(),
				authorId: currentUser.uid,
				authorName: currentUser.displayName,
				authorImgUrl: currentUser.photoURL,
			}),
		}).catch((err) => {
			setAlert({
				severity: 'error',
				message: 'Noe gikk galt under lagring',
			});
			setSavingEvalNote(false);
			setSelectedItem();
			return null;
		});
		setAlert({ severity: 'success', message: 'Evalueringsnotat lagret' });
		setEvalNote('');
		setSavingEvalNote(false);
		setSelectedItem(null);
	};
	const handleUpdateEvalForm = async () => {
		await updateDoc(doc(db, 'incidents', id), {
			evalNotes: {
				eval1: eval1,
				eval2: eval2,
				eval3: eval3,
				eval4_1: eval4_1,
				eval4_2: eval4_2,
				eval5_1: eval5_1,
				eval5_2: eval5_2,
				eval6_1: eval6_1,
				eval6_2: eval6_2,
				eval7_1: eval7_1,
				eval7_2: eval7_2,
				eval8_1: eval8_1,
				eval8_2: eval8_2,
				eval9_1: eval9_1,
				eval9_2: eval9_2,
				eval10_1: eval10_1,
				eval10_2: eval10_2,
				eval22_1: eval22_1,
				eval22_2: eval22_2,
				eval11_1: eval11_1,
				eval11_2: eval11_2,
				eval11_3: eval11_3,
				eval12: eval12,
				eval13: eval13,
				eval14: eval14,
				eval15: eval15,
				eval16: eval16,
				eval17: eval17,
				eval18: eval18,
				eval19: eval19,
				eval20: eval20,
				eval21: eval21,
			},
		}).catch((err) => {
			setAlert({
				severity: 'error',
				message: 'Noe gikk galt under lagring',
			});
			setSavingEvalNote(false);
			setSelectedItem();
			return null;
		});
	};
	const handleEndEval = async () => {
		const handleTestPdfFunction = httpsCallable(
			functions,
			'createPDFReport'
		);
		handleTestPdfFunction({ incidentId: id });
		await updateDoc(doc(db, 'incidents', id), {
			evalState: 'ended',
			'evalValues.ended': Timestamp.now(),
		}).catch((err) => {
			setAlert({
				severity: 'error',
				message: 'Noe gikk galt under lagring',
			});
			setSavingEvalNote(false);
			setSelectedItem();
			return null;
		});
		history.push('/');
	};
	useEffect(() => {
		let unsub = onSnapshot(
			doc(db, 'incidents', id),
			(doc) => {
				setIncidentData({ ...doc.data(), id: id });
				setLoading(false);
			},
			(error) => {
				setLoading(false);
			}
		);
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'messages'),
			orderBy('created', 'desc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setMessageList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'photos'),
			orderBy('created', 'desc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setPhotoList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'polygons'),
			orderBy('created', 'desc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setPolygonList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'polylines'),
			orderBy('created', 'desc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setPolyLineList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'markers'),
			orderBy('created', 'desc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setMarkerList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		if (incidentData) {
			let interval = null;
			if (isPlaying) {
				if (sliderState >= incidentData.ended.seconds) {
					clearInterval(interval);
				} else {
					interval = setInterval(() => {
						setSliderState((sliderState) => sliderState + 1);
					}, 1000 / playbackSpeed);
				}
			} else if (
				!isPlaying &&
				sliderState !== incidentData.created.seconds
			) {
				clearInterval(interval);
			}
			return () => {
				clearInterval(interval);
			};
		}
	}, [isPlaying, sliderState, incidentData, playbackSpeed]);
	useEffect(() => {
		if (incidentData) {
			setSliderState(incidentData.created.seconds);
		}
	}, [incidentData]);
	useEffect(() => {
		const tempList = [
			...messageList,
			...markerList,
			...photoList,
			...polyLineList,
			...polygonList,
		];
		const sortedList = tempList.sort((a, b) => a['created'] - b['created']);
		setSliderMarks(sortedList);
	}, [messageList, markerList, photoList, polyLineList, polygonList]);
	useEffect(() => {
		sliderMarks.forEach((mark, index) => {
			if (sliderState >= mark.created.seconds) {
				setCurrentIndex(index);
			} else if (sliderState < sliderMarks[0].created.seconds) {
				setCurrentIndex(-1);
			}
		});
	}, [sliderMarks, sliderState]);
	if (selectedItem && selectedItem.changes) {
		console.log(selectedItem.changes);
	}
	console.log(sliderMarks);
	return incidentData ? (
		<>
			{selectedItem && (
				<Dialog
					open={Boolean(selectedItem)}
					onClose={() => {
						setSelectedItem(null);
					}}
					fullWidth
					maxWidth='md'
				>
					{selectedItem.markerUrl && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							<Grid container>
								<Grid item sx={{ marginRight: 1 }}>
									<Avatar
										variant='square'
										src={selectedItem.markerUrl}
									/>
								</Grid>
								<Grid item>
									POI:{' '}
									{selectedItem.type ||
										'Feil under lasting av POI type...'}
								</Grid>
							</Grid>
						</DialogTitle>
					)}
					{selectedItem.changes && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							Chatmelding
						</DialogTitle>
					)}
					{selectedItem.drawOrder && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							{selectedItem.drawOrder === 4 && 'Rødt '}
							{selectedItem.drawOrder === 3 && 'Gult '}
							{selectedItem.drawOrder === 2 && 'Grønt '}
							Polygon
						</DialogTitle>
					)}
					{selectedItem.isLine && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							{selectedItem.color === 'black' && 'Sort '}
							{selectedItem.color === 'red' && 'Rød '}
							{selectedItem.color === 'yellow' && 'Gul '}
							{selectedItem.color === 'green' && 'Grønn '}
							{selectedItem.color === 'blue' && 'Blå '}
							{selectedItem.color === 'orange' && 'Oransje '}
							{selectedItem.dashed ? 'stiplet ' : 'heltrukken '}
							Linje
						</DialogTitle>
					)}
					{selectedItem.photoUrl && (
						<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
							Foto
						</DialogTitle>
					)}
					<DialogContent>
						<List>
							<ListItem>
								<ListItemAvatar sx={{ marginRight: 2 }}>
									<Avatar
										src={selectedItem.authorImgUrl || null}
										sx={{ height: 60, width: 60 }}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={
										'Opprettet av: ' +
										selectedItem.authorName
									}
									secondary={
										<Typography variant='body2'>
											{selectedItem.created &&
												moment
													.unix(
														selectedItem.created
															.seconds
													)
													.format(
														'DD.MM-YYYY[, kl. ]HH:mm:ss'
													)}
										</Typography>
									}
								/>
							</ListItem>
						</List>
						<Divider />
						{selectedItem.photoUrl && (
							<img
								src={selectedItem.photoUrl}
								width='100%'
								alt='Bilde'
							/>
						)}

						<Typography variant='body1'>
							<strong>Info:</strong>
						</Typography>
						<Typography variant='body2'>
							{selectedItem.message}
						</Typography>
						<Divider sx={{ marginTop: 3 }}>EVALUERING:</Divider>
						{selectedItem.evalNotes &&
							selectedItem.evalNotes.map((note, index) => {
								return (
									<React.Fragment key={index}>
										<ListItem>
											<ListItemAvatar>
												<Avatar
													src={note.authorImgUrl}
												/>
											</ListItemAvatar>
											<ListItemText
												primary={note.note}
												secondary={moment
													.unix(note.created.seconds)
													.format('HH:mm:ss')}
											/>
										</ListItem>
										<Divider />
									</React.Fragment>
								);
							})}
						<TextField
							sx={{ marginTop: 1 }}
							variant='outlined'
							multiline
							fullWidth
							rows={5}
							label='Evalueringsnotat:'
							value={evalNote}
							onChange={(evt) => {
								setEvalNote(evt.target.value);
							}}
						/>
						<Grid container spacing={1} sx={{ marginTop: 1 }}>
							<Grid item xs={12} sm={6}>
								<Button
									disabled={
										!Boolean(evalNote) || savingEvalNote
									}
									fullWidth
									variant='contained'
									color='primary'
									onClick={handleSaveEvalNote}
								>
									Lagre
								</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button
									fullWidth
									variant='contained'
									color='secondary'
									onClick={() => {
										setEvalNote('');
										setSelectedItem(null);
									}}
								>
									Avbryt
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
			{showEndDialog && (
				<Dialog
					open={showEndDialog}
					onClose={() => {
						setShowEndDialog(false);
					}}
				>
					<DialogTitle>Vil du avslutte?</DialogTitle>
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={6}>
								<Button
									variant='contained'
									onClick={handleEndEval}
								>
									JA
								</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button
									variant='contained'
									onClick={() => {
										setShowEndDialog(false);
									}}
								>
									NEI
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
			{showEvalDialog && (
				<Dialog
					maxWidth='md'
					fullWidth
					open={showEvalDialog}
					onClose={() => {
						setShowEvalDialog(false);
						handleUpdateEvalForm();
					}}
				>
					<DialogContent>
						<Grid container spacing={1}>
							{evalIndex === 0 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Situasjonsbeskrivelse:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											sx={{ marginTop: 1 }}
											fullWidth
											variant='outlined'
											multiline
											value={eval1}
											onChange={(evt) => {
												setEval1(evt.target.value);
											}}
											rows={5}
											label='Hva har skjedd?'
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											sx={{ marginTop: 1 }}
											fullWidth
											variant='outlined'
											multiline
											rows={5}
											value={eval2}
											onChange={(evt) => {
												setEval2(evt.target.value);
											}}
											label='Hvilke tiltak ble iverksatt?'
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											sx={{ marginTop: 1 }}
											fullWidth
											variant='outlined'
											multiline
											rows={5}
											value={eval3}
											onChange={(evt) => {
												setEval3(evt.target.value);
											}}
											label='Hvilke aktører var involvert?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 1 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Umiddelbare erfaringspunkter:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Mottak og håndtering av meldinger:
										</Typography>
										<List dense>
											<ListItem>
												- Loggføring/dokumentasjon
											</ListItem>
											<ListItem>
												- Ble det foretatt en
												situasjonsvurdering?
											</ListItem>
										</List>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval4_1}
											onChange={(evt) => {
												setEval4_1(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte bra?'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval4_2}
											onChange={(evt) => {
												setEval4_2(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte mindre bra?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 2 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Umiddelbare erfaringspunkter:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Varsling
										</Typography>
										<List dense>
											<ListItem>
												Internt i virksomheten
											</ListItem>
											<ListItem>
												Eksternt til andre involverte
											</ListItem>
											<ListItem>
												Gjort iht. beredskapsplanen?
											</ListItem>
										</List>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval5_1}
											onChange={(evt) => {
												setEval5_1(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte bra?'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval5_2}
											onChange={(evt) => {
												setEval5_2(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte mindre bra?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 3 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Umiddelbare erfaringspunkter:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Organisering og gjennomføring av
											krisehåndteringen:
										</Typography>
										<List dense>
											<ListItem>
												Fordeling av ansvar og roller
											</ListItem>
											<ListItem>
												Tilgang på ressurser, folk og
												utstyr
											</ListItem>
											<ListItem>
												Egnethet av lokaler
											</ListItem>
										</List>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											rows={5}
											value={eval6_1}
											onChange={(evt) => {
												setEval6_1(evt.target.value);
											}}
											label='Hva fungerte bra?'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval6_2}
											onChange={(evt) => {
												setEval6_2(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte mindre bra?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 4 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Umiddelbare erfaringspunkter:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Samarbeid:
										</Typography>
										<List dense>
											<ListItem>
												Samarbeid med eksterne aktører
											</ListItem>
										</List>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval7_1}
											onChange={(evt) => {
												setEval7_1(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte bra?'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval7_2}
											onChange={(evt) => {
												setEval7_2(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte mindre bra?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 5 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Umiddelbare erfaringspunkter:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Kommunikasjon:
										</Typography>
										<List dense>
											<ListItem>
												Internt i kommunen/ virksomheten
											</ListItem>
											<ListItem>
												Med myndigheter og
												samarbeidspartnere
											</ListItem>
											<ListItem>
												Med befolkning/allmenheten
											</ListItem>
											<ListItem>
												Med berørte og pårørende
											</ListItem>
										</List>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											value={eval8_1}
											onChange={(evt) => {
												setEval8_1(evt.target.value);
											}}
											fullWidth
											rows={5}
											label='Hva fungerte bra?'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											value={eval8_2}
											onChange={(evt) => {
												setEval8_2(evt.target.value);
											}}
											fullWidth
											rows={5}
											label='Hva fungerte mindre bra?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 6 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Umiddelbare erfaringspunkter:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Mediehåndtering:
										</Typography>
										<List dense>
											<ListItem>
												Utforming av budskap og
												talsperson
											</ListItem>
											<ListItem>
												Aviser, radio, tv
											</ListItem>
											<ListItem>Sosiale medier</ListItem>
											<ListItem>
												Pressekonferanse
											</ListItem>
											<ListItem>Annet</ListItem>
										</List>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											rows={5}
											value={eval9_1}
											onChange={(evt) => {
												setEval9_1(evt.target.value);
											}}
											label='Hva fungerte bra?'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval9_2}
											onChange={(evt) => {
												setEval9_2(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte mindre bra?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 7 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Umiddelbare erfaringspunkter:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Ivaretakelse av berørte og
											pårørende:
										</Typography>
										<List dense>
											<ListItem>
												Gjennom f.eks. evakuertog
												pårørendesenter og
												tilrettelegging for samling av
												berørte og pårørende
											</ListItem>
										</List>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval10_1}
											onChange={(evt) => {
												setEval10_1(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte bra?'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval10_2}
											onChange={(evt) => {
												setEval10_2(evt.target.value);
											}}
											rows={5}
											label='Hva fungerte mindre bra?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 8 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Umiddelbare erfaringspunkter:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Helse, miljø og sikkerhet:
										</Typography>
										<List dense>
											<ListItem>
												For egne ansatte, samt evt.
												engasjerte ressurser.
											</ListItem>
										</List>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											rows={5}
											value={eval22_1}
											onChange={(evt) => {
												setEval22_1(evt.target.value);
											}}
											label='Hva fungerte bra?'
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											multiline
											value={eval22_2}
											onChange={(evt) => {
												setEval22_2(evt.target.value);
											}}
											fullWidth
											rows={5}
											label='Hva fungerte mindre bra?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 9 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body1'>
											Hvilke umiddelbare tiltak bør
											iverksettes etter hendelsen?
										</Typography>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval11_1}
											onChange={(evt) => {
												setEval11_1(evt.target.value);
											}}
											rows={5}
											label='Umiddelbare tiltak'
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval11_2}
											onChange={(evt) => {
												setEval11_2(evt.target.value);
											}}
											rows={5}
											label='Hvem følger opp tiltaket?'
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval11_3}
											onChange={(evt) => {
												setEval11_3(evt.target.value);
											}}
											rows={5}
											label='Når?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 10 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å tydeliggjøre
											eller gjøre endringer med hensyn til
											ansvar, roller og samarbeid ved
											(denne type) uønskede hendelse?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval12}
											onChange={(evt) => {
												setEval12(evt.target.value);
											}}
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 11 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å gjøre endringer i
											overordnede styringsdokumenter for
											samfunnssikkerhet og beredskap?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval13}
											onChange={(evt) => {
												setEval13(evt.target.value);
											}}
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 12 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å oppdatere risiko-
											og sårbarhetsanalyser?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											value={eval14}
											onChange={(evt) => {
												setEval14(evt.target.value);
											}}
											fullWidth
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 13 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å gjøre iverksette
											andre forebyggende tiltak?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval15}
											onChange={(evt) => {
												setEval15(evt.target.value);
											}}
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 14 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å endre planverket?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval16}
											onChange={(evt) => {
												setEval16(evt.target.value);
											}}
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 15 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å oppdatere
											øvelsesplan?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval17}
											onChange={(evt) => {
												setEval17(evt.target.value);
											}}
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 16 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å styrke tilgangen
											på relevante ressurser for å
											håndtere denne type hendelse?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											value={eval18}
											onChange={(evt) => {
												setEval18(evt.target.value);
											}}
											fullWidth
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 17 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å styrke
											samarbeidet med andre virksomheter?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval19}
											onChange={(evt) => {
												setEval19(evt.target.value);
											}}
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 18 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Videre oppfølging:
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant='body2'>
											Er det behov for å gjøre en mer
											omfattende evaluering av hendelsen?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											value={eval20}
											onChange={(evt) => {
												setEval20(evt.target.value);
											}}
											fullWidth
											rows={5}
											label='Hvis ja, hvordan?'
										/>
									</Grid>
								</>
							)}
							{evalIndex === 19 && (
								<>
									<Grid item xs={12}>
										<Typography variant='h6'>
											Andre forhold som ønskes tatt
											opp/fulgt opp?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<TextField
											variant='outlined'
											multiline
											fullWidth
											value={eval21}
											onChange={(evt) => {
												setEval21(evt.target.value);
											}}
											rows={5}
											label='Beskriv kort'
										/>
									</Grid>
								</>
							)}
							<Grid item xs={12} sm={6}>
								<Button
									fullWidth
									variant='contained'
									disabled={evalIndex === 0 ? true : false}
									onClick={() => {
										handleUpdateEvalForm();
										evalIndex > 0
											? setEvalIndex(evalIndex - 1)
											: setEvalIndex(0);
									}}
								>
									Forrige
								</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button
									fullWidth
									variant='contained'
									onClick={() => {
										evalIndex === 19
											? setEvalIndex(19)
											: setEvalIndex(evalIndex + 1);

										if (evalIndex === 19) {
											setShowEvalDialog(false);
										}
										handleUpdateEvalForm();
									}}
								>
									{evalIndex === 19
										? 'Lagre og avslutt'
										: 'Neste'}
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Box sx={{ width: '100%', mr: 1 }}>
										<LinearProgress
											variant='determinate'
											value={Math.round(
												(evalIndex / 19) * 100
											)}
										/>
									</Box>
									<Box sx={{ minWidth: 35 }}>
										<Typography
											variant='body2'
											color='text.secondary'
										>{`${Math.round(
											(evalIndex / 19) * 100
										)}%`}</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
			<MapContainer
				style={{ width: '100%', height: '45vh' }}
				center={[
					incidentData.editable.position._lat,
					incidentData.editable.position._long,
				]}
				zoom={13}
				scrollWheelZoom={true}
				zoomControl={false}
			>
				<LayersControl position='topright'>
					<LayersControl.BaseLayer checked name='Open street map'>
						<TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						/>
					</LayersControl.BaseLayer>
					<LayersControl.BaseLayer name='Norge Topo'>
						<WMSTileLayer
							format='image/png'
							layers='topo4'
							url='http://opencache.statkart.no/gatekeeper/gk/gk.open'
							attribution='&copy; <a href="http://kartverket.no">Kartverket</a>'
						/>
					</LayersControl.BaseLayer>
					<LayersControl.Overlay checked name='Hendelsessted'>
						<Marker
							position={[
								incidentData.editable.position._lat,
								incidentData.editable.position._long,
							]}
							icon={incidentIcon}
						/>
					</LayersControl.Overlay>

					<LayersControl.Overlay checked name='Polygoner'>
						<LayerGroup>
							{polygonList &&
								polygonList.map((polygon) => {
									if (sliderState < polygon.created.seconds) {
										return null;
									}
									return (
										polygon.positions && (
											<Polygon
												key={polygon.id}
												pathOptions={{
													color: 'black',
													fillColor:
														polygon.color || null,
													fillOpacity: '20%',
												}}
												eventHandlers={{
													click: () => {},
												}}
												positions={polygon.positions.map(
													(pos) => {
														return [
															pos._lat,
															pos._long,
														];
													}
												)}
											/>
										)
									);
								})}
						</LayerGroup>
					</LayersControl.Overlay>
					<LayersControl.Overlay checked name='Linjer'>
						<LayerGroup>
							{polyLineList &&
								polyLineList.map((polyline) => {
									if (
										sliderState < polyline.created.seconds
									) {
										return null;
									}
									return (
										polyline.positions && (
											<Polyline
												key={polyline.id}
												pathOptions={{
													color: polyline.color,
													dashArray: polyline.dashed
														? '20'
														: '0',
													weight: 7,
													opacity: '60%',
												}}
												positions={polyline.positions.map(
													(pos) => {
														return [
															pos._lat,
															pos._long,
														];
													}
												)}
												eventHandlers={{
													click: () => {},
												}}
											/>
										)
									);
								})}
						</LayerGroup>
					</LayersControl.Overlay>
					<LayersControl.Overlay checked name='POI'>
						<LayerGroup>
							{markerList &&
								markerList.map((marker) => {
									if (sliderState < marker.created.seconds) {
										return null;
									}
									const newIcon = new L.Icon({
										iconUrl: marker.markerUrl,
										iconRetinaUrl: marker.markerUrl,
										iconSize: new L.Point(50, 50),
										iconAnchor: [25, 50],
										popupAnchor: [0, -50],
									});
									return (
										marker.position && (
											<Marker
												key={marker.id}
												position={[
													marker.position._lat,
													marker.position._long,
												]}
												icon={newIcon}
												eventHandlers={{
													click: () => {},
												}}
											/>
										)
									);
								})}
						</LayerGroup>
					</LayersControl.Overlay>
					<LayersControl.Overlay checked name='Foto'>
						<LayerGroup>
							{photoList &&
								photoList.map((photo) => {
									if (sliderState < photo.created.seconds) {
										return null;
									} else {
										const newIcon = new L.Icon({
											iconUrl:
												'https://squaremap.no/icons/mapIconsINSITU/camera.svg',
											iconRetinaUrl:
												'https://squaremap.no/icons/mapIconsINSITU/camera.svg',
											iconSize: new L.Point(50, 50),
											iconAnchor: [25, 50],
											popupAnchor: [0, -50],
										});
										return (
											photo.position && (
												<Marker
													key={photo.id}
													position={[
														photo.position._lat,
														photo.position._long,
													]}
													icon={newIcon}
													eventHandlers={{
														click: () => {},
													}}
												/>
											)
										);
									}
								})}
						</LayerGroup>
					</LayersControl.Overlay>
				</LayersControl>
			</MapContainer>
			<Box sx={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
				<Slider
					sx={{ height: 15 }}
					min={incidentData.created.seconds}
					max={incidentData.ended.seconds}
					step={1}
					marks={sliderMarks.map((msg) => {
						return {
							value: msg.created.seconds,
						};
					})}
					componentsProps={{ mark: { sx: { height: 20 } } }}
					value={sliderState}
					valueLabelFormat={() => {
						return moment.unix(sliderState).format('HH:mm:ss');
					}}
					onChange={(evt) => {
						setSliderState(evt.target.value);
					}}
					valueLabelDisplay='on'
				/>
			</Box>
			<Container maxWidth='sm'>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						'& > *': {
							m: 1,
						},
					}}
				>
					<IconButton size='large' onClick={handlePrevEvent}>
						<FirstPageIcon />
					</IconButton>
					<IconButton size='large' onClick={handleTogglePlayback}>
						{!isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
					</IconButton>
					<IconButton size='large' onClick={handleNextEvent}>
						<LastPageIcon />
					</IconButton>
					<FormControl sx={{ m: 1, minWidth: 115 }}>
						<InputLabel id='playbackspeed-label'>
							Hastighet
						</InputLabel>
						<Select
							labelId='Hastighet'
							label='Hastighet'
							value={playbackSpeed}
							onChange={(evt) => {
								setPlaybackSpeed(evt.target.value);
							}}
						>
							<MenuItem value={1}>1X</MenuItem>
							<MenuItem value={2}>2X</MenuItem>
							<MenuItem value={5}>5X</MenuItem>
							<MenuItem value={10}>10X</MenuItem>
							<MenuItem value={20}>20X</MenuItem>
							<MenuItem value={50}>50X</MenuItem>
							<MenuItem value={100}>100X</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Container>
			<Container>
				{sliderMarks[currentIndex] ? (
					<>
						<ListItem
							sx={{ border: '1px solid black' }}
							alignItems='flex-start'
							secondaryAction={
								<>
									<IconButton
										edge='end'
										color='primary'
										size='large'
										onClick={() => {
											setSelectedItem(
												sliderMarks[currentIndex]
											);
										}}
									>
										<PlagiarismIcon
											sx={{ fontSize: '1.2em' }}
										/>
									</IconButton>
									<IconButton
										edge='end'
										color='primary'
										size='large'
									>
										<ZoomInIcon
											sx={{ fontSize: '1.2em' }}
										/>
									</IconButton>
								</>
							}
						>
							<ListItemAvatar>
								<Avatar
									src={sliderMarks[currentIndex].authorImgUrl}
									sx={{
										width: '80px',
										height: '80px',
										marginRight: 2,
									}}
								/>
							</ListItemAvatar>
							<ListItemText
								disableTypography
								primary={
									<Typography variant='body1'>
										<strong>{'Gjeldende element'}</strong>
										<br />
										<i>
											Opprettet av:
											{
												sliderMarks[currentIndex]
													.authorName
											}
											,<br />
											{moment
												.unix(
													sliderMarks[currentIndex]
														.created.seconds
												)
												.format(
													'DD.MM-YY[, kl. ]HH:mm'
												)}
										</i>
									</Typography>
								}
								secondary={
									<>
										<Typography variant='body2'>
											{sliderMarks[currentIndex].message}
										</Typography>
									</>
								}
							/>
						</ListItem>
						{sliderMarks[currentIndex].evalNotes && (
							<ListItem
								sx={{
									border: '1px solid black',
									backgroundColor: 'lightcyan',
								}}
							>
								<ListItemText primary='Evalueringsnotater:' />
							</ListItem>
						)}
						{sliderMarks[currentIndex].evalNotes &&
							sliderMarks[currentIndex].evalNotes.map(
								(note, index) => {
									return (
										<React.Fragment key={index}>
											<ListItem
												sx={{
													border: '1px solid black',
												}}
												alignItems='flex-start'
											>
												<ListItemAvatar>
													<Avatar
														src={note.authorImgUrl}
													/>
												</ListItemAvatar>
												<ListItemText
													primary={note.note}
													secondary={moment
														.unix(
															note.created.seconds
														)
														.format('HH:mm:ss')}
												/>
											</ListItem>
										</React.Fragment>
									);
								}
							)}
					</>
				) : (
					<ListItem
						sx={{ border: '1px solid black' }}
						alignItems='flex-start'
					>
						<ListItemAvatar>
							<Avatar
								src={incidentData.authorImgUrl}
								sx={{
									width: '80px',
									height: '80px',
									marginRight: 2,
								}}
							/>
						</ListItemAvatar>
						<ListItemText
							disableTypography
							primary={
								<Typography variant='body1'>
									<strong>{'Start av hendelse'}</strong>
									<br />
									<i>
										Opprettet av:
										{incidentData.authorName}
										,<br />
										{moment
											.unix(incidentData.created.seconds)
											.format('DD.MM-YY[, kl. ]HH:mm')}
									</i>
								</Typography>
							}
							secondary={
								<>
									<Typography variant='body2'>
										<strong>
											{incidentData.editable.title}
										</strong>
									</Typography>
									<Typography variant='body2'>
										{incidentData.editable.description}
									</Typography>
								</>
							}
						/>
					</ListItem>
				)}
				<Container>
					{sliderMarks[currentIndex + 1] ? (
						<ListItem
							sx={{
								border: '1px solid black',
								marginTop: 1,
								backgroundColor: 'lightGrey',
							}}
							alignItems='flex-start'
						>
							<ListItemAvatar>
								<Avatar
									src={
										sliderMarks[currentIndex + 1]
											.authorImgUrl
									}
									sx={{
										width: '80px',
										height: '80px',
										marginRight: 2,
									}}
								/>
							</ListItemAvatar>
							<ListItemText
								disableTypography
								primary={
									<Typography variant='body1'>
										<strong>{'Neste element'}</strong>
										<br />
										<i>
											Opprettet av:
											{
												sliderMarks[currentIndex + 1]
													.authorName
											}
											,<br />
											{moment
												.unix(
													sliderMarks[
														currentIndex + 1
													].created.seconds
												)
												.format(
													'DD.MM-YY[, kl. ]HH:mm'
												)}
										</i>
									</Typography>
								}
								secondary={
									<>
										<Typography variant='body2'>
											{
												sliderMarks[currentIndex + 1]
													.message
											}
										</Typography>
									</>
								}
							/>
						</ListItem>
					) : (
						<ListItem
							sx={{
								border: '1px solid black',
								marginTop: 1,
								backgroundColor: 'lightGrey',
							}}
							alignItems='flex-start'
						>
							<ListItemAvatar>
								<Avatar
									src={incidentData.authorImgUrl}
									sx={{
										width: '80px',
										height: '80px',
										marginRight: 2,
									}}
								/>
							</ListItemAvatar>
							<ListItemText
								disableTypography
								primary={
									<Typography variant='body1'>
										<strong>{'Hendelse avsluttet'}</strong>
										<br />
										<i>
											Opprettet av:
											{incidentData.authorName}
											,<br />
											{moment
												.unix(
													incidentData.ended.seconds
												)
												.format(
													'DD.MM-YY[, kl. ]HH:mm'
												)}
										</i>
									</Typography>
								}
							/>
						</ListItem>
					)}
				</Container>
				<Divider sx={{ marginTop: 2 }} />
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<Button
							variant='contained'
							onClick={() => {
								setShowEvalDialog(true);
							}}
							fullWidth
						>
							Åpne Evalueringsskjema
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							variant='contained'
							onClick={() => {
								setShowEndDialog(true);
							}}
						>
							Avslutt evaluering
						</Button>
					</Grid>
				</Grid>
			</Container>
		</>
	) : (
		<h1>Nope</h1>
	);
}
