import {
	Avatar,
	Button,
	Chip,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material';
import {
	AddBox as AddBoxIcon,
	Message as MessageIcon,
	Group as GroupIcon,
	Article as ArticleIcon,
	NotificationImportant as NotificationImportantIcon,
	MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import CreateNewIncident from './CreateNewIncident';
import CreateNewMessage from './CreateNewMessage';
import GroupMembers from './GroupMembers';
import GroupEvaluationList from './GroupEvaluationList';
import {
	collection,
	limit,
	onSnapshot,
	orderBy,
	query,
	where,
} from 'firebase/firestore';
import { db } from '../../Services/firebase';
import { useAuth } from '../../Context/AuthContext';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export default function GroupPage() {
	const [showNewIncident, setShowNewIncident] = useState(false);
	const [showNewMessage, setShowNewMessage] = useState(false);
	const [showGroupMembers, setShowGroupMembers] = useState(false);
	const [showEvalMode, setShowEvalMode] = useState(false);
	const [lastLoadedIncidentSnap, setLastLoadedIncidentSnap] = useState(null);
	const { userData } = useAuth();
	const [incidentList, setIncidentList] = useState([]);
	const [messageList, setMessageList] = useState([]);
	const history = useHistory();
	useEffect(() => {
		const q = query(
			collection(db, 'incidents'),
			where('ended', '==', null),
			where('groupId', '==', userData.group.groupId),
			orderBy('created', 'desc'),
			limit(5)
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setLastLoadedIncidentSnap(querySnapshot);
			setIncidentList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [userData]);
	useEffect(() => {
		const q = query(
			collection(db, 'groups', userData.group.groupId, 'messages'),
			orderBy('created', 'desc'),
			limit(5)
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setMessageList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [userData]);
	return (
		<Paper sx={{ padding: 3 }} variant='outlined'>
			{showNewIncident && (
				<CreateNewIncident setShowNewIncident={setShowNewIncident} />
			)}
			{showNewMessage && (
				<CreateNewMessage setShowNewMessage={setShowNewMessage} />
			)}
			{showGroupMembers && (
				<GroupMembers setShowGroupMembers={setShowGroupMembers} />
			)}
			{showEvalMode && (
				<GroupEvaluationList setShowEvalMode={setShowEvalMode} />
			)}
			{!showNewIncident &&
				!showNewMessage &&
				!showGroupMembers &&
				!showEvalMode && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								variant='outlined'
								color='primary'
								size='large'
								startIcon={<AddBoxIcon />}
								onClick={() => {
									setShowNewIncident(true);
								}}
							>
								Ny hendelse
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								variant='outlined'
								color='primary'
								size='large'
								startIcon={<MessageIcon />}
								onClick={() => {
									setShowNewMessage(true);
								}}
							>
								Ny melding
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								variant='outlined'
								color='primary'
								size='large'
								startIcon={<GroupIcon />}
								onClick={() => {
									setShowGroupMembers(true);
								}}
							>
								Gruppe
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								variant='outlined'
								color='primary'
								size='large'
								startIcon={<ArticleIcon />}
								onClick={() => {
									setShowEvalMode(true);
								}}
							>
								Evaluering
							</Button>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant='h6'>
								Siste 5 åpne hendelser:
							</Typography>
							<List>
								{incidentList.length > 0 ? (
									incidentList.map((incident, index) => {
										console.log(incident);
										return (
											<React.Fragment key={index}>
												<ListItemButton
													alignItems='flex-start'
													onClick={() => {
														history.push(
															'/hendelse/' +
																incident.id
														);
													}}
													sx={
														incident.editable
															.isTraining
															? {
																	backgroundColor:
																		'lightBlue',
															  }
															: {}
													}
												>
													<ListItemAvatar>
														<Avatar
															src={
																incident.authorImgUrl
															}
															sx={{
																width: '80px',
																height: '80px',
																marginRight: 2,
															}}
														/>
													</ListItemAvatar>
													<ListItemText
														disableTypography
														primary={
															<Typography variant='body1'>
																<strong>
																	{
																		incident
																			.editable
																			.title
																	}
																</strong>
																<br />
																Opprettet av:{' '}
																{
																	incident.authorName
																}
																,<br />{' '}
																{moment
																	.unix(
																		incident
																			.created
																			.seconds
																	)
																	.format(
																		'DD.MM-YYYY[, kl. ]HH:mm:ss'
																	)}
															</Typography>
														}
														secondary={
															<>
																<Typography variant='body2'>
																	{
																		incident
																			.editable
																			.description
																	}
																</Typography>
																{incident &&
																	incident.editable &&
																	incident
																		.editable
																		.isTraining && (
																		<Chip
																			variant='outlined'
																			label='Øvelse'
																		/>
																	)}
															</>
														}
													/>
												</ListItemButton>
												<Divider />
											</React.Fragment>
										);
									})
								) : (
									<>
										<ListItem alignItems='flex-start'>
											<ListItemAvatar>
												<Avatar
													sx={{
														width: '80px',
														height: '80px',
														marginRight: 2,
													}}
												>
													<NotificationImportantIcon
														sx={{
															width: '50px',
															height: '50px',
														}}
													/>
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												disableTypography
												primary={
													<Typography variant='body1'>
														<strong>
															Ingen hendelser
															funnet
														</strong>
													</Typography>
												}
												secondary={
													<>
														<Typography variant='body2'>
															Det finnes ingen
															åpne hendelser
															tilhørende denne
															gruppen...
														</Typography>
													</>
												}
											/>
										</ListItem>
										<Divider />
									</>
								)}
							</List>
							<ListItemButton>
								<ListItemIcon>
									<MoreHorizIcon />
								</ListItemIcon>
								<ListItemText primary='Last flere hendelser...' />
							</ListItemButton>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant='h6'>
								Siste 5 meldinger:
							</Typography>
							<List>
								{messageList.length > 0 ? (
									messageList.map((message, index) => {
										return (
											<React.Fragment key={index}>
												<ListItemButton alignItems='flex-start'>
													<ListItemAvatar>
														<Avatar
															src={
																message.authorImgUrl
															}
															sx={{
																width: '80px',
																height: '80px',
																marginRight: 2,
															}}
														/>
													</ListItemAvatar>
													<ListItemText
														disableTypography
														primary={
															<Typography variant='body1'>
																<strong>
																	{
																		message.title
																	}
																</strong>
																<br />
																<i>
																	Opprettet
																	av:
																	{
																		message.authorName
																	}
																	,<br />
																	{moment
																		.unix(
																			message
																				.created
																				.seconds
																		)
																		.format(
																			'DD.MM-YYYY[, kl. ]HH:mm:ss'
																		)}
																</i>
															</Typography>
														}
														secondary={
															<>
																<Typography variant='body2'>
																	{
																		message.message
																	}
																</Typography>
															</>
														}
													/>
												</ListItemButton>
												<Divider />
											</React.Fragment>
										);
									})
								) : (
									<React.Fragment>
										<ListItem alignItems='flex-start'>
											<ListItemAvatar>
												<Avatar
													sx={{
														width: '80px',
														height: '80px',
														marginRight: 2,
													}}
												>
													<NotificationImportantIcon
														sx={{
															width: '50px',
															height: '50px',
														}}
													/>
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												disableTypography
												primary={
													<Typography variant='body1'>
														<strong>
															Ingen melding
															tilgjengelig
														</strong>
													</Typography>
												}
												secondary={
													<>
														<Typography variant='body2'>
															Det finnes ingen
															meldinger som
															tilhører denne
															gruppen...
														</Typography>
													</>
												}
											/>
										</ListItem>
										<Divider />
									</React.Fragment>
								)}
							</List>
						</Grid>
					</Grid>
				)}
		</Paper>
	);
}
