import {
	AppBar,
	Avatar,
	Container,
	Hidden,
	Toolbar,
	Typography,
} from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import LogoImg from '../assets/images/logo.png';
export default function Header(props) {
	const match = useRouteMatch('/hendelse/:slug');
	return (
		<>
			{!match ? (
				<AppBar
					position='static'
					onClick={() => {
						if (props.currentUser) {
							props.setMenuOpen(true);
						}
					}}
				>
					<Container>
						<Toolbar>
							<Avatar variant='square' src={LogoImg} />
							<Typography
								variant='h6'
								sx={{ flexGrow: 1, marginLeft: 2 }}
							>
								<Hidden smDown>SQUARE</Hidden>
							</Typography>
						</Toolbar>
					</Container>
				</AppBar>
			) : (
				!props.menuOpen &&
				props.currentUser && (
					<Avatar
						variant='square'
						src={LogoImg}
						style={{
							width: 50,
							height: 50,
							position: 'absolute',
							top: 5,
							left: 5,
							zIndex: 500,
						}}
						onClick={() => {
							props.setMenuOpen(true);
						}}
					/>
				)
			)}
		</>
	);
}
