import { CircularProgress, Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../Services/firebase';
import { useAuth } from '../../Context/AuthContext';
import Incident from './Incident';
import IncidentLobby from './IncidentLobby';

export default function IncidentContainer() {
	const { currentUser } = useAuth();
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [incident, setIncident] = useState(null);
	const [allowAccess, setAllowAccess] = useState(false);
	useEffect(() => {
		let unsub = onSnapshot(
			doc(db, 'incidents', id),
			(doc) => {
				setIncident({ ...doc.data(), id: id });
				setLoading(false);
				if (
					doc.data() &&
					(doc.data().roles.admin.includes(currentUser.uid) ||
						doc.data().roles.member.includes(currentUser.uid) ||
						doc.data().roles.guest.includes(currentUser.uid))
				) {
					setAllowAccess(true);
				}
			},
			(error) => {
				setLoading(false);
				setAllowAccess(false);
			}
		);
		return () => {
			unsub();
		};
	}, [id]);
	return (
		<>
			{loading ? (
				<Container>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box sx={{ height: 200, marginTop: '20%' }}>
							<CircularProgress size={200} />
							<Typography variant='body1'>
								Leter etter hendelse...
							</Typography>
						</Box>
					</Box>
				</Container>
			) : (
				<>
					{incident && allowAccess && (
						<Incident incident={incident} />
					)}
					{incident && !allowAccess && (
						<IncidentLobby incident={incident} />
					)}
					{!incident && (
						<Container>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<Box sx={{ height: 200, marginTop: '20%' }}>
									<Typography variant='h2'>
										Ingen hendelse funnet...
									</Typography>
								</Box>
							</Box>
						</Container>
					)}
				</>
			)}
		</>
	);
}
