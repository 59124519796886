import {
	Alert,
	Avatar,
	Button,
	SpeedDial,
	SpeedDialAction,
	SpeedDialIcon,
	Card,
	CardHeader,
	CardContent,
	List,
	Grid,
	IconButton,
	TextField,
	Container,
	Typography,
	Slider,
	Badge,
	Divider,
	FormControlLabel,
	FormLabel,
	FormControl,
	RadioGroup,
	Radio,
	InputAdornment,
	Snackbar,
} from '@mui/material';
import {
	Chat as ChatIcon,
	Timeline as TimelineIcon,
	PanoramaHorizontal as PanoramaHorizontalIcon,
	AddLocation as AddLocationIcon,
	Close as CloseIcon,
	AddAPhoto as AddAPhotoIcon,
	FilterAlt as FilterAltIcon,
} from '@mui/icons-material';
import {
	MapContainer,
	TileLayer,
	Marker,
	LayersControl,
	WMSTileLayer,
	Polygon,
	LayerGroup,
	Polyline,
	Popup,
	Tooltip,
	GeoJSON,
} from 'react-leaflet';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
	doc,
	onSnapshot,
	query,
	collection,
	GeoPoint,
	orderBy,
	addDoc,
	Timestamp,
	updateDoc,
	arrayUnion,
	where,
	collectionGroup,
} from 'firebase/firestore';
import { db, functions } from '../../Services/firebase';
import L from 'leaflet';
import PoiInfo from './PoiInfo';
import MapActions from './MapActions';
import PoiSettingsDialog from './PoiSettingsDialog';
import PolygonSettingsDialog from './PolygonSettingsDialog';
import LineSettingsDialog from './LineSettingsDialog';
import ChatMsg from './ChatMsg';
import { useAuth } from '../../Context/AuthContext';
import { useMenu } from '../../Context/MenuContext';
import IncidentInfoDialog from './IncidentInfoDialog';
import MemberDialog from './MemberDialog';
import EndDialog from './EndDialog';
import handleMarkerIcon from '../../assets/icons/handleMarker.svg';
import PhotoSettingsDialog from './PhotoSettingsDialog';
import moment from 'moment';
import GeolocationDialog from './GeolocationDialog';
import useLocalStorage from '../../Components/Hooks/useLocalStorage';
import avatarMissing from '../../assets/icons/avatar.jpg';
//import MemberInfo from './MemberInfo';
import { useHistory } from 'react-router-dom';
import BaseStasjonJSON from './BaseStasjonJSON';

const handleMarker = new L.Icon({
	iconUrl: handleMarkerIcon,
	iconRetinaUrl: handleMarkerIcon,
	iconSize: new L.Point(30, 30),
});

export default function Incident(props) {
	const { currentUser } = useAuth();
	const {
		showMembersDialog,
		showIncidentInfoDialog,
		setShowIncidentInfoDialog,
		showEndDialog,
		setShowEndDialog,
		setFoundIncident,
		showSlider,
		showGeolocationDialog,
	} = useMenu();
	const [createPoiMode, setCreatePoiMode] = useState(false);
	const [tmpPoiPosition, setTmpPoiPosition] = useState(null);
	const [createPolyMode, setCreatePolyMode] = useState(false);
	const [tmpPolyPositions, setTmpPolyPositions] = useState([]);
	const [showPolySettingsDialog, setShowPolySettingsDialog] = useState(false);
	const [createLineMode, setCreateLineMode] = useState(false);
	const [tmpLinePositions, setTmpLinePositions] = useState([]);
	const [showLineSettingsDialog, setShowLineSettingsDialog] = useState(false);
	const [createPhotoMode, setCreatePhotoMode] = useState(false);
	const [tmpPhotoPosition, setTmpPhotoPosition] = useState(false);
	const [changeIncidentPositionMode, setChangeIncidentPositionMode] =
		useState(false);
	const { id } = useParams();
	const [lastReadChat, setLastReadChat] = useLocalStorage(id, 'none');
	const [numUnreadChat, setNumUnreadChat] = useState(0);
	const [showOwnLocation, setShowOwnLocation] = useLocalStorage(
		'showSelf',
		false
	);

	const incident = props.incident;
	const [userPos, setUserPos] = useState(null);
	const [messages, setMessages] = useState([]);
	const [chatOpen, setChatOpen] = useState(false);
	const [chatDisplayValue, setChatDisplayValue] = useState('alle');
	const [chatFilterText, setChatFilterText] = useState('');
	const [eventList, setEventList] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [polygons, setPolygons] = useState([]);
	const [polylines, setPolylines] = useState([]);
	const [photos, setPhotos] = useState([]);
	const [members, setMembers] = useState([]);
	const [activeFeature, setActiveFeature] = useState(null);
	const [activeMember, setActiveMember] = useState(null);
	const [sliderValue, setSliderValue] = useState(
		Timestamp.now().seconds - (Timestamp.now().seconds % 900) + 900
	);
	const [mapClickPos, setMapClickPos] = useState(null);

	const [message, setMessage] = useState('');
	const [showMsgAlert, setShowMsgAlert] = useState(false);

	const [saving, setSaving] = useState(false);
	const history = useHistory();
	useEffect(() => {
		const q = query(collection(db, 'incidents', id, 'users'));
		const unsub = onSnapshot(q, (querySnapshot) => {
			setMembers(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	/*
	useEffect(() => {
		const q = query(collection(db, 'incidents', id, 'events'));
		const unsub = onSnapshot(q, (querySnapshot) => {
			setEventList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	*/
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'messages'),
			orderBy('created', 'desc')
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setMessages(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'markers'),
			where('dontShow', '!=', true)
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setMarkers(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'polygons'),
			where('dontShow', '!=', true)
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			let tempData = querySnapshot.docs.map((doc) => ({
				...doc.data(),
				id: doc.id,
			}));
			setPolygons(
				tempData.sort((a, b) => {
					return a.drawOrder - b.drawOrder;
				})
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'photos'),
			where('dontShow', '!=', true)
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setPhotos(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		const q = query(
			collection(db, 'incidents', id, 'polylines'),
			where('dontShow', '!=', true)
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setPolylines(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [id]);
	useEffect(() => {
		if (false) {
			setShowMsgAlert(true);
		} else {
			setShowMsgAlert(false);
		}
	}, [showMsgAlert]);

	useEffect(() => {
		if (incident && incident.ended) {
			setFoundIncident(false);
		}
		if (incident && !incident.ended) {
			setFoundIncident(true);
		}
		return () => {
			setFoundIncident(false);
		};
	}, [incident]);

	useEffect(() => {
		let unreadMsgCount = 0;
		if (chatOpen && messages[0]) {
			setLastReadChat(messages[0].id);
		} else {
			for (let i = 0; i < messages.length; i++) {
				if (messages[i].id === lastReadChat) {
					break;
				}
				unreadMsgCount++;
			}
		}
		setNumUnreadChat(unreadMsgCount);
	}, [messages, lastReadChat]);

	const handleMapClick = async (e) => {
		if (createPoiMode) {
			setTmpPoiPosition(new GeoPoint(e.latlng.lat, e.latlng.lng));
		} else if (createPolyMode) {
			setTmpPolyPositions((tmpPolyPositions) => [
				...tmpPolyPositions,
				new GeoPoint(e.latlng.lat, e.latlng.lng),
			]);
		} else if (createLineMode) {
			setTmpLinePositions((tmpLinePositions) => [
				...tmpLinePositions,
				new GeoPoint(e.latlng.lat, e.latlng.lng),
			]);
		} else if (createPhotoMode) {
			setTmpPhotoPosition(new GeoPoint(e.latlng.lat, e.latlng.lng));
		} else if (changeIncidentPositionMode) {
			handleUpdateIncidentPosition(
				new GeoPoint(e.latlng.lat, e.latlng.lng)
			);
			setChangeIncidentPositionMode(false);
		} else {
			setMapClickPos({ lat: e.latlng.lat, lon: e.latlng.lng });
		}
	};
	const handleNewMsg = async () => {
		if (!message) {
			return;
		}
		setSaving(true);
		try {
			await addDoc(collection(db, 'incidents', id, 'messages'), {
				authorId: currentUser.uid,
				authorImgUrl: currentUser.photoURL,
				authorName: currentUser.displayName,
				created: Timestamp.now(),
				message: message,
				isImportant: false,
				isHidden: false,
				isMediaFriendly: false,
				changes: [],
			});
			setSaving(false);
			setMessage('');
		} catch (err) {
			setSaving(false);
			console.log(err);
		}
	};
	const handleChatSetImportant = async (chatId, checked, oldvalue) => {
		await updateDoc(doc(db, 'incidents', id, 'messages', chatId), {
			isImportant: checked,
			changes: arrayUnion({
				changeTime: Timestamp.now(),
				changedById: currentUser.uid,
				changedByName: currentUser.displayName,
				change: 'Endret viktighet fra ' + oldvalue + ' til ' + checked,
			}),
		});
	};
	const handleChatSetMediaFriendly = async (chatId, checked, oldvalue) => {
		await updateDoc(doc(db, 'incidents', id, 'messages', chatId), {
			isMediaFriendly: checked,
			changes: arrayUnion({
				changeTime: Timestamp.now(),
				changedById: currentUser.uid,
				changedByName: currentUser.displayName,
				change:
					'Endret medievennlig fra ' + oldvalue + ' til ' + checked,
			}),
		});
	};
	const handleChatSetVisibility = async (chatId, checked, oldvalue) => {
		await updateDoc(doc(db, 'incidents', id, 'messages', chatId), {
			isHidden: checked,
			changes: arrayUnion({
				changeTime: Timestamp.now(),
				changedById: currentUser.uid,
				changedByName: currentUser.displayName,
				change: 'Endret skjult fra ' + oldvalue + ' til ' + checked,
			}),
		});
	};
	const handleEndIncident = async () => {
		await updateDoc(doc(db, 'incidents', id), {
			ended: Timestamp.now(),
			evalState: 'pending',
		}).catch((err) => {
			console.log(err);
		});
		setShowEndDialog(false);
		history.push(`/`);
	};
	const updateTmpPoly = (evt, index) => {
		let newPositions = [...tmpPolyPositions];
		newPositions[index] = new GeoPoint(
			evt.target._latlng.lat,
			evt.target._latlng.lng
		);
		setTmpPolyPositions(newPositions);
	};
	const updateTmpLine = (evt, index) => {
		let newPositions = [...tmpLinePositions];
		newPositions[index] = new GeoPoint(
			evt.target._latlng.lat,
			evt.target._latlng.lng
		);
		setTmpLinePositions(newPositions);
	};
	const handleUpdateIncidentPosition = async (position) => {
		await updateDoc(doc(db, 'incidents', id), {
			'editable.position': position,
		});
	};
	const incidentIcon = new L.Icon({
		iconUrl: 'https://squaremap.no/icons/mapIconsINSITU/1.1.3.svg',
		iconRetinaUrl: 'https://squaremap.no/icons/mapIconsINSITU/1.1.3.svg',
		iconSize: new L.Point(65, 65),
		iconAnchor: [25, 50],
		popupAnchor: [0, -50],
	});
	return (
		<>
			{incident && showSlider && (
				<Slider
					sx={{
						position: 'absolute',
						top: '80px',
						left: '10vw',
						width: '80vw',
						zIndex: 1000,
					}}
					onChange={(evt) => {
						setSliderValue(evt.target.value);
					}}
					value={sliderValue}
					max={
						Timestamp.now().seconds -
						(Timestamp.now().seconds % 900) +
						900
					}
					min={
						incident.created.seconds -
						(incident.created.seconds % 900)
					}
					step={
						Timestamp.now().seconds - incident.created.seconds <
						86400
							? 900
							: 3600
					}
					valueLabelDisplay='on'
					valueLabelFormat={() => {
						return moment
							.unix(sliderValue)
							.format('DD.MM-YY[, kl. ]HH:mm');
					}}
				/>
			)}
			{showMembersDialog && (
				<MemberDialog members={members} incident={incident} />
			)}
			{showIncidentInfoDialog && (
				<IncidentInfoDialog
					incidentData={incident}
					setChangeIncidentPositionMode={
						setChangeIncidentPositionMode
					}
					id={id}
				/>
			)}
			{showEndDialog && (
				<EndDialog
					currentUser={currentUser}
					incidentData={incident}
					handleEndIncident={handleEndIncident}
				/>
			)}
			{chatOpen && (
				<Card
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '90vw',
						height: '100vh',
						zIndex: 10000,
						background: 'rgba(255, 255, 255, 0.50)',
						overflow: 'auto',
					}}
				>
					<CardHeader
						title='CHAT'
						sx={{ background: 'white', marginBottom: 1 }}
						action={
							<IconButton
								onClick={() => {
									setChatOpen(false);
								}}
							>
								<CloseIcon />
							</IconButton>
						}
					/>
					<CardContent sx={{ padding: 0, overflow: 'auto' }}>
						<Grid
							container
							spacing={1}
							sx={{ paddingLeft: 2, paddingRight: 2 }}
						>
							{(incident.roles.member.includes(currentUser.uid) ||
								incident.roles.admin.includes(
									currentUser.uid
								)) && (
								<>
									{showMsgAlert && (
										<>
											<Alert severity='error'>
												Det kan se ut som du har satt
												inn et navn i chatten. Før du
												evt publiserer det, ber i deg
												tenke gjennom GDPR
											</Alert>
										</>
									)}
									<Grid item xs={9}>
										<TextField
											label='Melding'
											fullWidth
											value={message}
											sx={{
												backgroundColor: 'white',
												marginTop: 1,
											}}
											onChange={(evt) => {
												setMessage(evt.target.value);
											}}
											onKeyUp={(evt) => {
												if (evt.key === 'Enter') {
													handleNewMsg();
												}
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<Button
											size='large'
											color='primary'
											fullWidth
											variant='contained'
											style={{ height: '100%' }}
											onClick={handleNewMsg}
											disabled={saving}
										>
											Send
										</Button>
									</Grid>
								</>
							)}
							<Grid item xs={12}>
								<FormControl component='fieldset'>
									<FormLabel component='legend'>
										Vis:
									</FormLabel>
									<RadioGroup
										row
										value={chatDisplayValue}
										onChange={(evt) => {
											setChatDisplayValue(
												evt.target.value
											);
										}}
									>
										<FormControlLabel
											value='alle'
											control={<Radio />}
											label='Alle'
										/>
										<FormControlLabel
											value='viktig'
											control={<Radio />}
											label='Vis kun viktige'
										/>
										<FormControlLabel
											value='medievennlig'
											control={<Radio />}
											label='Vis kun medievenlige'
										/>
										<FormControlLabel
											value='filter'
											control={<Radio />}
											label='Filtrer'
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							{chatDisplayValue === 'filter' && (
								<Grid item xs={12}>
									<TextField
										label='Filtrer'
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<FilterAltIcon />
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														edge='end'
														onClick={() => {
															setChatFilterText(
																''
															);
														}}
													>
														<CloseIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
										variant='outlined'
										fullWidth
										value={chatFilterText}
										onChange={(evt) => {
											setChatFilterText(evt.target.value);
										}}
										sx={{
											backgroundColor: 'white',
											marginTop: 1,
										}}
									/>
								</Grid>
							)}
						</Grid>
						<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
						<List>
							{messages.length > 0 &&
								messages.map((message, index) => {
									if (
										message.isHidden &&
										message.authorId !== currentUser.uid &&
										currentUser.uid !== incident.authorId
									) {
										return null;
									} else {
										if (chatDisplayValue === 'filter') {
											if (chatFilterText !== '') {
												if (
													!message.message
														.toLowerCase()
														.includes(
															chatFilterText
														) &&
													!message.authorName
														.toLowerCase()
														.includes(
															chatFilterText
														)
												) {
													return null;
												}
											}
										}
										if (
											chatDisplayValue === 'viktig' &&
											message.isImportant === false
										) {
											return null;
										} else if (
											chatDisplayValue ===
												'medievennlig' &&
											message.isMediaFriendly === false
										) {
											return null;
										} else {
											return (
												<ChatMsg
													key={index}
													chatMsg={message}
													handleSetImportant={
														handleChatSetImportant
													}
													handleSetMediaFriendly={
														handleChatSetMediaFriendly
													}
													handleSetVisibility={
														handleChatSetVisibility
													}
													incident={incident}
												/>
											);
										}
									}
								})}
						</List>
					</CardContent>
				</Card>
			)}
			{(incident.roles.admin.includes(currentUser.uid) ||
				incident.roles.member.includes(currentUser.uid)) && (
				<SpeedDial
					ariaLabel='Hurtigvelger features'
					sx={{
						position: 'absolute',
						bottom: 25,
						right: 25,
						zIndex: 500,
					}}
					icon={<SpeedDialIcon />}
				>
					<SpeedDialAction
						sx={{ height: '60px', width: '60px' }}
						icon={<AddLocationIcon />}
						tooltipOpen
						tooltipTitle='POI'
						onClick={() => {
							setCreateLineMode(false);
							setCreatePolyMode(false);
							setCreatePhotoMode(false);
							setCreatePoiMode(true);
						}}
					/>
					<SpeedDialAction
						sx={{ height: '60px', width: '60px' }}
						icon={<PanoramaHorizontalIcon />}
						tooltipOpen
						tooltipTitle='Polygon'
						onClick={() => {
							setCreateLineMode(false);
							setCreatePolyMode(true);
							setCreatePhotoMode(false);
							setCreatePoiMode(false);
						}}
					/>
					<SpeedDialAction
						sx={{ height: '60px', width: '60px' }}
						icon={<TimelineIcon />}
						tooltipOpen
						tooltipTitle='Linje'
						onClick={() => {
							setCreateLineMode(true);
							setCreatePolyMode(false);
							setCreatePhotoMode(false);
							setCreatePoiMode(false);
						}}
					/>
					<SpeedDialAction
						sx={{ height: '60px', width: '60px' }}
						icon={<AddAPhotoIcon />}
						tooltipOpen
						tooltipTitle='Foto'
						onClick={() => {
							setCreateLineMode(false);
							setCreatePolyMode(false);
							setCreatePhotoMode(true);
							setCreatePoiMode(false);
						}}
					/>
				</SpeedDial>
			)}
			{incident && !incident.ended && (
				<Avatar
					variant='square'
					style={{
						width: 50,
						height: 50,
						position: 'absolute',
						backgroundColor: 'white',
						top: 5,
						left: 70,
						zIndex: 500,
					}}
					onClick={() => {
						if (messages[0]) {
							setLastReadChat(messages[0].id);
						}
						setNumUnreadChat(0);
						setChatOpen(true);
					}}
				>
					<Badge
						badgeContent={numUnreadChat}
						color='secondary'
						overlap='circular'
					>
						<ChatIcon
							style={{ width: 40, height: 40 }}
							color='primary'
						/>
					</Badge>
				</Avatar>
			)}
			{activeFeature && (
				<PoiInfo
					incident={incident}
					activeFeature={activeFeature}
					setActiveFeature={setActiveFeature}
					id={id}
				/>
			)}
			{/*
			<MemberInfo
				incident={incident}
				activeMember={activeMember}
				setActiveMember={setActiveMember}
			/>*/}
			{createPoiMode && (
				<Alert
					severity='info'
					action={
						<>
							<Button
								color='inherit'
								size='small'
								onClick={() => {
									setCreatePoiMode(false);
								}}
							>
								Avbryt
							</Button>
						</>
					}
					sx={{
						position: 'absolute',
						top: 60,
						right: 5,
						left: 5,
						zIndex: 1000,
					}}
				>
					Du kan nå opprette POI ved å klikke i kartet
				</Alert>
			)}
			{createPolyMode && (
				<Alert
					severity='info'
					action={
						<>
							<Button
								color='inherit'
								size='small'
								onClick={() => {
									setShowPolySettingsDialog(true);
								}}
							>
								Ferdig
							</Button>
							<Button
								color='inherit'
								size='small'
								onClick={() => {
									setCreatePolyMode(false);
									setTmpPolyPositions([]);
								}}
							>
								Avbryt
							</Button>
						</>
					}
					sx={{
						position: 'absolute',
						top: 60,
						right: 5,
						left: 5,
						zIndex: 1000,
					}}
				>
					Du kan nå opprette et polygon ved å klikke flere steder i
					kartet
				</Alert>
			)}
			{createLineMode && (
				<Alert
					severity='info'
					action={
						<>
							<Button
								color='inherit'
								size='small'
								onClick={() => {
									setShowLineSettingsDialog(true);
								}}
							>
								Ferdig
							</Button>
							<Button
								color='inherit'
								size='small'
								onClick={() => {
									setTmpLinePositions([]);
									setCreateLineMode(false);
								}}
							>
								Avbryt
							</Button>
						</>
					}
					sx={{
						position: 'absolute',
						top: 60,
						right: 5,
						left: 5,
						zIndex: 1000,
					}}
				>
					Du kan nå opprette en linje ved å klikke flere punkt i
					kartet
				</Alert>
			)}
			{createPhotoMode && (
				<Alert
					severity='info'
					action={
						<>
							<Button
								color='inherit'
								size='small'
								onClick={() => {
									setCreatePhotoMode(false);
								}}
							>
								Avbryt
							</Button>
						</>
					}
					sx={{
						position: 'absolute',
						top: 60,
						right: 5,
						left: 5,
						zIndex: 1000,
					}}
				>
					Du kan nå opprette foto ved å klikke i kartet der bildet
					skal være.
				</Alert>
			)}
			{changeIncidentPositionMode && (
				<Alert
					severity='info'
					action={
						<>
							<Button
								color='inherit'
								size='small'
								onClick={() => {
									setChangeIncidentPositionMode(false);
									setShowIncidentInfoDialog(true);
								}}
							>
								Avbryt
							</Button>
						</>
					}
					sx={{
						position: 'absolute',
						top: 60,
						right: 5,
						left: 5,
						zIndex: 1000,
					}}
				>
					Klikk i kartet på ny lokasjon for hendelse...
				</Alert>
			)}
			{mapClickPos && (
				<Snackbar
					open={Boolean(mapClickPos)}
					autoHideDuration={6000}
					onClose={(e, r) => {
						if (r === 'clickaway') {
							return;
						}
						setMapClickPos(null);
					}}
					message={
						'Lat: ' +
						mapClickPos.lat.toFixed(5) +
						' Lon: ' +
						mapClickPos.lon.toFixed(5)
					}
				/>
			)}
			{tmpPoiPosition && (
				<PoiSettingsDialog
					tmpPoiPosition={tmpPoiPosition}
					setTmpPoiPosition={setTmpPoiPosition}
					setCreatePoiMode={setCreatePoiMode}
				/>
			)}
			{showPolySettingsDialog && (
				<PolygonSettingsDialog
					setTmpPolyPositions={setTmpPolyPositions}
					setCreatePolyMode={setCreatePolyMode}
					showPolySettingsDialog={showPolySettingsDialog}
					setShowPolySettingsDialog={setShowPolySettingsDialog}
					positions={tmpPolyPositions}
				/>
			)}
			{showLineSettingsDialog && (
				<LineSettingsDialog
					setTmpLinePositions={setTmpLinePositions}
					setCreateLineMode={setCreateLineMode}
					showLineSettingsDialog={showLineSettingsDialog}
					setShowLineSettingsDialog={setShowLineSettingsDialog}
					positions={tmpLinePositions}
				/>
			)}
			{tmpPhotoPosition && (
				<PhotoSettingsDialog
					tmpPhotoPosition={tmpPhotoPosition}
					setTmpPhotoPosition={setTmpPhotoPosition}
					setCreatePhotoMode={setCreatePhotoMode}
				/>
			)}
			{showGeolocationDialog && (
				<GeolocationDialog
					showOwnLocation={showOwnLocation}
					setShowOwnLocation={setShowOwnLocation}
				/>
			)}
			{incident && !incident.ended ? (
				<MapContainer
					style={{ width: '100vw', height: '100vh' }}
					center={[
						incident.editable.position._lat,
						incident.editable.position._long,
					]}
					zoom={13}
					scrollWheelZoom={true}
					zoomControl={false}
				>
					<LayersControl position='topright'>
						<LayersControl.BaseLayer checked name='Open street map'>
							<TileLayer
								attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
							/>
						</LayersControl.BaseLayer>
						<LayersControl.BaseLayer name='Norge Topo'>
							<WMSTileLayer
								format='image/png'
								layers='topo4'
								url='http://opencache.statkart.no/gatekeeper/gk/gk.open'
								attribution='&copy; <a href="http://kartverket.no">Kartverket</a>'
							/>
						</LayersControl.BaseLayer>
						<LayersControl.Overlay name='Aktsomhetsområde Flom'>
							<WMSTileLayer
								attribution='GeoData'
								url={
									'https://nve.geodataonline.no/arcgis/services/FlomAktsomhet/MapServer/WMSServer?'
								}
								layers={'Flom_aktsomhetsomrade'}
								format='image/png24'
								opacity={0.5}
							/>
						</LayersControl.Overlay>
						<LayersControl.Overlay name='Maksimal vannstandstigning'>
							<WMSTileLayer
								attribution='GeoData'
								url={
									'https://nve.geodataonline.no/arcgis/services/FlomAktsomhet/MapServer/WMSServer?'
								}
								layers={'MaksimalVannstandstigning'}
								format='image/png24'
								opacity={0.5}
							/>
						</LayersControl.Overlay>
						<LayersControl.Overlay name='Nødnett HH Agder'></LayersControl.Overlay>
						<LayersControl.Overlay checked name='Hendelsessted'>
							<LayerGroup>
								<Marker
									position={[
										incident.editable.position._lat,
										incident.editable.position._long,
									]}
									icon={incidentIcon}
									eventHandlers={{
										click: () => {
											if (
												!createPolyMode &&
												!createPoiMode &&
												!createLineMode
											) {
												setShowIncidentInfoDialog(true);
											}
										},
									}}
								/>
							</LayerGroup>
						</LayersControl.Overlay>
						<LayersControl.Overlay checked name='Polygoner'>
							<LayerGroup>
								{polygons &&
									polygons.map((polygon) => {
										if (
											sliderValue <
												polygon.created.seconds &&
											showSlider
										) {
											return null;
										}
										return (
											polygon.positions && (
												<Polygon
													key={polygon.id}
													pathOptions={{
														color: 'black',
														fillColor:
															polygon.color ||
															null,
														fillOpacity: '20%',
													}}
													eventHandlers={{
														click: () => {
															if (
																!createPolyMode &&
																!createPoiMode &&
																!createLineMode
															) {
																setActiveFeature(
																	polygon
																);
															}
														},
													}}
													positions={polygon.positions.map(
														(pos) => {
															return [
																pos._lat,
																pos._long,
															];
														}
													)}
												/>
											)
										);
									})}
							</LayerGroup>
						</LayersControl.Overlay>
						<LayersControl.Overlay checked name='Linjer'>
							<LayerGroup>
								{polylines &&
									polylines.map((polyline) => {
										if (
											sliderValue <
												polyline.created.seconds &&
											showSlider
										) {
											return null;
										}
										return (
											polyline.positions && (
												<Polyline
													key={polyline.id}
													pathOptions={{
														color: polyline.color,
														dashArray:
															polyline.dashed
																? '20'
																: '0',
														weight: 7,
														opacity: '60%',
													}}
													positions={polyline.positions.map(
														(pos) => {
															return [
																pos._lat,
																pos._long,
															];
														}
													)}
													eventHandlers={{
														click: () => {
															if (
																!createPolyMode &&
																!createPoiMode &&
																!createLineMode
															) {
																setActiveFeature(
																	polyline
																);
															}
														},
													}}
												/>
											)
										);
									})}
							</LayerGroup>
						</LayersControl.Overlay>
						<LayersControl.Overlay checked name='POI'>
							<LayerGroup>
								{markers &&
									markers.map((marker) => {
										if (
											sliderValue <
												marker.created.seconds &&
											showSlider
										) {
											return null;
										}
										const newIcon = new L.Icon({
											iconUrl: marker.markerUrl,
											iconRetinaUrl: marker.markerUrl,
											iconSize: new L.Point(50, 50),
											iconAnchor: [25, 50],
											popupAnchor: [0, -50],
										});
										return (
											marker.position && (
												<Marker
													key={marker.id}
													position={[
														marker.position._lat,
														marker.position._long,
													]}
													icon={newIcon}
													eventHandlers={{
														click: () => {
															if (
																!createPolyMode &&
																!createPoiMode &&
																!createLineMode
															) {
																setActiveFeature(
																	marker
																);
															}
														},
													}}
												>
													{marker.type ===
														'Kun synlig merkelapp' && (
														<Tooltip
															direction='top'
															permanent
														>
															{marker.message.slice(
																0,
																15
															)}
														</Tooltip>
													)}
												</Marker>
											)
										);
									})}
							</LayerGroup>
						</LayersControl.Overlay>
						<LayersControl.Overlay checked name='Foto'>
							<LayerGroup>
								{photos &&
									photos.map((photo) => {
										if (
											sliderValue < photo.created.seconds
										) {
											return null;
										} else {
											const newIcon = new L.Icon({
												iconUrl:
													'https://squaremap.no/icons/mapIconsINSITU/camera.svg',
												iconRetinaUrl:
													'https://squaremap.no/icons/mapIconsINSITU/camera.svg',
												iconSize: new L.Point(50, 50),
												iconAnchor: [25, 50],
												popupAnchor: [0, -50],
											});
											return (
												photo.position && (
													<Marker
														key={photo.id}
														position={[
															photo.position._lat,
															photo.position
																._long,
														]}
														icon={newIcon}
														eventHandlers={{
															click: () => {
																if (
																	!createPhotoMode &&
																	!createPolyMode &&
																	!createPoiMode &&
																	!createLineMode
																) {
																	setActiveFeature(
																		photo
																	);
																}
															},
														}}
													/>
												)
											);
										}
									})}
							</LayerGroup>
						</LayersControl.Overlay>
						<BaseStasjonJSON />
					</LayersControl>

					<MapActions
						handleMapClick={handleMapClick}
						showMyLocation={showOwnLocation}
						setUserPos={setUserPos}
					/>
					{tmpPolyPositions && (
						<>
							{tmpPolyPositions.map((pos, index) => {
								return (
									<Marker
										position={[pos._lat, pos._long]}
										draggable
										title={index}
										eventHandlers={{
											dragend: (evt) => {
												updateTmpPoly(evt, index);
											},
										}}
										icon={handleMarker}
									/>
								);
							})}
							<Polygon
								pathOptions={{
									color: 'blue',
									dashArray: '20 15',
									fillColor: 'blue',
									fillOpacity: '10%',
								}}
								positions={tmpPolyPositions.map((pos) => {
									return [pos._lat, pos._long];
								})}
							/>
						</>
					)}
					{tmpLinePositions.length > 1 && (
						<>
							{tmpLinePositions.map((pos, index) => {
								return (
									<Marker
										position={[pos._lat, pos._long]}
										draggable
										title={index}
										eventHandlers={{
											dragend: (evt) => {
												updateTmpLine(evt, index);
											},
										}}
										icon={handleMarker}
									/>
								);
							})}
							<Polyline
								pathOptions={{ color: 'blue', dashArray: '15' }}
								positions={tmpLinePositions.map((pos) => {
									return [pos._lat, pos._long];
								})}
							/>
						</>
					)}
				</MapContainer>
			) : (
				<Container maxWidth='sm' sx={{ paddingTop: 10 }}>
					<Typography variant='h6'>
						Ingen tilgjengelig hendelse...
					</Typography>
				</Container>
			)}
		</>
	);
}
