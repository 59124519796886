import React, { useEffect, useState } from 'react';
import { GeoJSON, LayerGroup, LayersControl, useMap } from 'react-leaflet';

export default function BaseStasjonJSON() {
	const [features, setFeatures] = useState([]);
	const map = useMap();
	//console.log(map.getZoom());
	useEffect(() => {
		const context = require.context(
			'../../assets/mockData/va',
			true,
			/.json$/
		);
		context.keys().forEach(async (key) => {
			const fileName = key.replace('./', '');
			const resource =
				await require(`../../assets/mockData/va/${fileName}`);
			let newFeatures = features;
			features.push(resource);
			setFeatures(newFeatures);
		});
	}, []);
	return (
		<>
			<LayersControl.Overlay name='8 features fra mob 99 Agder Rogaland'>
				<LayerGroup>
					{features &&
						features.map((feature, key) => {
							console.log('run');
							return (
								<React.Fragment key={key}>
									<GeoJSON data={feature} fil />
								</React.Fragment>
							);
						})}
				</LayerGroup>
			</LayersControl.Overlay>
		</>
	);
}
