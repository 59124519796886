import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material';
import { useMenu } from '../../Context/MenuContext';

export default function EndDialog({
	currentUser,
	handleEndIncident,
	incidentData,
}) {
	const { showEndDialog, setShowEndDialog } = useMenu();
	return (
		<Dialog
			open={showEndDialog}
			onClose={() => {
				setShowEndDialog(false);
			}}
			maxWidth='md'
			fullWidth
		>
			<DialogTitle>Avslutt hendelse</DialogTitle>
			<DialogContent>
				{incidentData.roles.admin.includes(currentUser.uid) ? (
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<Button
								variant='contained'
								fullWidth
								color='primary'
								onClick={handleEndIncident}
							>
								Avslutt hendelse
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant='contained'
								fullWidth
								color='secondary'
								onClick={() => {
									setShowEndDialog(false);
								}}
							>
								Avbryt
							</Button>
						</Grid>
					</Grid>
				) : (
					<Typography variant='h6'>
						Du har ikke rettighet til å avslutte hendelse...
					</Typography>
				)}
			</DialogContent>
		</Dialog>
	);
}
