import {
	Avatar,
	Button,
	Card,
	CardContent,
	Container,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { db, functions } from '../../Services/firebase';
import moment from 'moment';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import {
	addDoc,
	collection,
	doc,
	limit,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	Timestamp,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

export default function IncidentLobby({ incident }) {
	const { id } = useParams();
	const { currentUser, userData } = useAuth();
	const [allowApply, setAllowApply] = useState(false);
	const [messageList, setMessageList] = useState([]);
	const [message, setMessage] = useState('');
	const [sending, setSending] = useState(false);
	const [lobbyStatus, setLobbyStatus] = useState(null);
	const incidentIcon = new L.Icon({
		iconUrl: 'https://squaremap.no/icons/mapIconsINSITU/1.1.3.svg',
		iconRetinaUrl: 'https://squaremap.no/icons/mapIconsINSITU/1.1.3.svg',
		iconSize: new L.Point(65, 65),
		iconAnchor: [25, 50],
		popupAnchor: [0, -50],
	});
	const handleRequestAccessViaLobby = async () => {
		setSending(true);
		let queryData = {
			requestTime: Timestamp.now(),
			userId: currentUser.uid,
			userName: currentUser.displayName,
			userImgUrl: currentUser.photoURL,
		};
		if (
			userData &&
			userData.group &&
			userData.group.status &&
			userData.group.status !== 'pending'
		) {
			queryData = { ...queryData, ...userData };
		}

		await setDoc(
			doc(db, 'incidents', id, 'lobby', currentUser.uid),
			queryData
		).catch(() => {
			return;
		});
		if (message.length > 0) {
			handleSendMessage();
		}
		setSending(false);
	};
	const handleRequestAccessViaGroup = async () => {
		const requestIncidentAccess = httpsCallable(
			functions,
			'requestIncidentAccess'
		);
		requestIncidentAccess(incident);
	};
	const handleSendMessage = async () => {
		if (message.length > 0) {
			setSending(true);
			await addDoc(
				collection(
					db,
					'incidents',
					id,
					'lobby',
					currentUser.uid,
					'messages'
				),
				{
					message: message,
					authorId: currentUser.uid,
					authorName: currentUser.displayName,
					authorImgUrl: currentUser.photoURL,
					created: Timestamp.now(),
				}
			);
			setMessage('');
			setSending(false);
		}
	};
	useEffect(() => {
		if (
			incident &&
			incident.roles &&
			(!incident.roles.admin.includes(currentUser.uid) ||
				!incident.roles.member.includes(currentUser.uid) ||
				!incident.roles.guest.includes(currentUser.uid))
		) {
			setAllowApply(true);
		}
	}, [currentUser, incident]);
	useEffect(() => {
		const docRef = doc(db, 'incidents', id, 'lobby', currentUser.uid);
		const unsub = onSnapshot(docRef, (querySnapshot) => {
			setLobbyStatus(querySnapshot.data());
		});
		return () => {
			unsub();
		};
	}, [currentUser, id]);
	useEffect(() => {
		const q = query(
			collection(
				db,
				'incidents',
				id,
				'lobby',
				currentUser.uid,
				'messages'
			),
			orderBy('created', 'desc'),
			limit(10)
		);
		const unsub = onSnapshot(q, (querySnapshot) => {
			setMessageList(
				querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		});
		return () => {
			unsub();
		};
	}, [currentUser, id]);
	if (incident.editable) {
		return (
			<Container>
				<Card sx={{ marginTop: 10 }}>
					<CardContent>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6}>
								<MapContainer
									style={{ width: '100%', height: '300px' }}
									center={[
										incident.editable.position._lat,
										incident.editable.position._long,
									]}
									zoom={13}
									scrollWheelZoom={true}
									zoomControl={false}
								>
									<TileLayer
										attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
										url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
									/>
									<Marker
										position={[
											incident.editable.position._lat,
											incident.editable.position._long,
										]}
										icon={incidentIcon}
									/>
								</MapContainer>
							</Grid>
							<Grid item xs={12} md={6}>
								<ListItem alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar
											src={incident.authorImgUrl}
											sx={{
												width: '80px',
												height: '80px',
												marginRight: 2,
											}}
										/>
									</ListItemAvatar>
									<ListItemText
										disableTypography
										primary={
											<Typography variant='body1'>
												<strong>
													{'HendelseID: ' + id}
												</strong>
												<br />
												Opprettet av:{' '}
												{incident.authorName}
												,<br />{' '}
												{moment
													.unix(
														incident.created.seconds
													)
													.format(
														'DD.MM-YYYY[, kl. ]HH:mm:ss'
													)}
											</Typography>
										}
									/>
								</ListItem>
								{incident &&
								incident.groupId &&
								userData &&
								userData.group &&
								incident.groupId === userData.group.groupId &&
								userData.group.status === 'accepted' ? (
									<Button
										fullWidth
										color='primary'
										variant='contained'
										disabled={!allowApply || sending}
										onClick={handleRequestAccessViaGroup}
									>
										Bli med i hendelse
									</Button>
								) : (
									<>
										<TextField
											multiline
											rows={5}
											fullWidth
											label='Melding til admin'
											value={message}
											onChange={(evt) => {
												setMessage(evt.target.value);
											}}
											sx={{ marginBottom: 1 }}
										/>
										{lobbyStatus ? (
											<Button
												fullWidth
												color='primary'
												variant='contained'
												disabled={
													!allowApply || sending
												}
												onClick={handleSendMessage}
											>
												Send melding til admin
											</Button>
										) : (
											<Button
												fullWidth
												color='primary'
												variant='contained'
												disabled={
													!allowApply || sending
												}
												onClick={
													handleRequestAccessViaLobby
												}
											>
												Be om adgang til hendelse
											</Button>
										)}
									</>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				{lobbyStatus && (
					<Card sx={{ marginTop: 2 }}>
						<CardContent>
							<Typography variant='h6'>
								<strong>Status:</strong> Under behandling
							</Typography>
							<List>
								{messageList.length > 0 ? (
									messageList.map((message, index) => {
										return (
											<React.Fragment key={index}>
												<ListItem alignItems='flex-start'>
													<ListItemAvatar>
														<Avatar
															src={
																message.authorImgUrl
															}
															sx={{
																width: '80px',
																height: '80px',
																marginRight: 2,
															}}
														/>
													</ListItemAvatar>
													<ListItemText
														disableTypography
														primary={
															<Typography variant='body1'>
																<strong>
																	{
																		message.authorName
																	}
																</strong>
																<br />
																{moment
																	.unix(
																		message
																			.created
																			.seconds
																	)
																	.format(
																		'DD.MM-YYYY[, kl. ]HH:mm:ss'
																	)}
															</Typography>
														}
														secondary={
															<Paper
																elevation={10}
																sx={{
																	backgroundColor:
																		'lightgrey',
																	padding: 1,
																}}
															>
																<Typography variant='body2'>
																	{
																		message.message
																	}
																</Typography>
															</Paper>
														}
													/>
												</ListItem>
												<Divider />
											</React.Fragment>
										);
									})
								) : (
									<ListItem alignItems='flex-start'>
										<ListItemAvatar>
											<Avatar
												src={incident.authorImgUrl}
												sx={{
													width: '80px',
													height: '80px',
													marginRight: 2,
												}}
											/>
										</ListItemAvatar>
										<ListItemText
											disableTypography
											primary={
												<Typography variant='body1'>
													<strong>
														Otto André Haaland
													</strong>
													<br />
													{moment
														.unix(
															incident.created
																.seconds
														)
														.format(
															'DD.MM-YYYY[, kl. ]HH:mm:ss'
														)}
												</Typography>
											}
											secondary={
												<Paper
													elevation={10}
													sx={{
														backgroundColor:
															'lightgrey',
													}}
												>
													<Typography variant='body2'>
														blablablabla
													</Typography>
												</Paper>
											}
										/>
									</ListItem>
								)}
							</List>
						</CardContent>
					</Card>
				)}
			</Container>
		);
	} else {
		return null;
	}
}
