import { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

export function useMenu() {
	return useContext(MenuContext);
}

export function MenuProvider({ children }) {
	const [showMembersDialog, setShowMembersDialog] = useState(false);
	const [showIncidentInfoDialog, setShowIncidentInfoDialog] = useState(false);
	const [showInfoDialog, setShowInfoDialog] = useState(false);
	const [showEndDialog, setShowEndDialog] = useState(false);
	const [foundIncident, setFoundIncident] = useState(false);
	const [showSlider, setShowSlider] = useState(false);
	const [showGeolocationDialog, setShowGeolocationDialog] = useState(false);
	const [showCreateIncident, setShowCreateIncident] = useState(false);

	const value = {
		showMembersDialog,
		setShowMembersDialog,
		showIncidentInfoDialog,
		setShowIncidentInfoDialog,
		showInfoDialog,
		setShowInfoDialog,
		showEndDialog,
		setShowEndDialog,
		foundIncident,
		setFoundIncident,
		showSlider,
		setShowSlider,
		showGeolocationDialog,
		setShowGeolocationDialog,
		showCreateIncident,
		setShowCreateIncident,
	};
	return (
		<MenuContext.Provider value={value}>{children}</MenuContext.Provider>
	);
}
