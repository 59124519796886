import {
	Avatar,
	Divider,
	Drawer,
	IconButton,
	Stack,
	Button,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from '@mui/material';
import {
	HelpOutline as HelpOutlineIcon,
	ArrowBack as ArrowBackIcon,
	People as PeopleIcon,
	Cancel as CancelIcon,
	ExitToApp as ExitToAppIcon,
	Edit as EditIcon,
	Info as InfoIcon,
	Dashboard as DashboardIcon,
	LinearScale as LinearScaleIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import { useMenu } from '../Context/MenuContext';

export default function SideMenu(props) {
	const { currentUser, logout } = useAuth();
	const {
		setShowMembersDialog,
		setShowIncidentInfoDialog,
		setShowEndDialog,
		foundIncident,
		showSlider,
		setShowSlider,
	} = useMenu();

	return (
		currentUser && (
			<Drawer
				open={props.menuOpen}
				onClose={() => {
					props.setMenuOpen(false);
				}}
			>
				<Toolbar style={{ background: '#303633' }}>
					<IconButton
						size='large'
						edge='start'
						color='secondary'
						onClick={() => {
							props.setMenuOpen(false);
						}}
					>
						<ArrowBackIcon style={{ height: 40, width: 40 }} />
					</IconButton>
					<Typography
						variant='h3'
						style={{
							paddingRight: 20,
							paddingLeft: 20,
							fontWeight: 'bold',
							color: '#fff',
						}}
					>
						SQUARE
					</Typography>
					<IconButton size='large' edge='end' color='secondary'>
						<HelpOutlineIcon style={{ height: 40, width: 40 }} />
					</IconButton>
				</Toolbar>
				{currentUser && (
					<List>
						<ListItem>
							<ListItemAvatar sx={{ marginRight: 2 }}>
								<Avatar
									src={currentUser.photoURL}
									sx={{ height: 60, width: 60 }}
								/>
							</ListItemAvatar>
							<ListItemText
								primary={currentUser.displayName}
								secondary={
									<Typography variant='body2'>
										Tlf: {currentUser.phoneNumber}
									</Typography>
								}
							/>
						</ListItem>
					</List>
				)}
				<Stack
					direction='row'
					spacing={1}
					justifyContent='space-around'
					alignItems='center'
					paddingLeft={1}
					paddingRight={1}
				>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						size='small'
						startIcon={<EditIcon />}
						component={Link}
						onClick={() => {
							props.setMenuOpen(false);
						}}
						to='/min-profil'
					>
						Rediger profil
					</Button>
					<Button
						variant='contained'
						color='primary'
						fullWidth
						size='small'
						startIcon={<ExitToAppIcon />}
						onClick={() => {
							props.setMenuOpen(false);
							logout();
						}}
					>
						Logg ut
					</Button>
				</Stack>
				<Divider />

				{foundIncident && (
					<>
						<Typography variant='h6' style={{ paddingLeft: 10 }}>
							Aktiv hendelse:
						</Typography>
						<List>
							<ListItem
								button
								onClick={() => {
									setShowIncidentInfoDialog(true);
									props.setMenuOpen(false);
								}}
							>
								<ListItemIcon>
									<InfoIcon />
								</ListItemIcon>
								<ListItemText primary='Vis hendelsesinformasjon' />
							</ListItem>
							<ListItem
								button
								onClick={() => {
									setShowSlider(!showSlider);
									props.setMenuOpen(false);
								}}
							>
								<ListItemIcon>
									<LinearScaleIcon />
								</ListItemIcon>
								<ListItemText primary='Vis/skjul tids slider' />
							</ListItem>
							{/* 							<ListItem
								button
								onClick={() => {
									setShowGeolocationDialog(true);
									props.setMenuOpen(false);
								}}
							>
								<ListItemIcon>
									<GpsFixedIcon />
								</ListItemIcon>
								<ListItemText primary='Innstilling egen posisjon' />
							</ListItem> */}
							{/*}
							<ListItem button>
								<ListItemIcon>
									<MapIcon />
								</ListItemIcon>
								<ListItemText primary='Vis hendelse/kart' />
							</ListItem>
							<ListItem button>
								<ListItemIcon>
									<ChatIcon />
								</ListItemIcon>
								<ListItemText primary='Vis kun chat' />
							</ListItem>
                */}
							<ListItem
								button
								onClick={() => {
									setShowMembersDialog(true);
									props.setMenuOpen(false);
								}}
							>
								<ListItemIcon>
									<PeopleIcon />
								</ListItemIcon>
								<ListItemText primary='Deltagere' />
							</ListItem>
							<ListItem
								button
								onClick={() => {
									setShowEndDialog(true);
									props.setMenuOpen(false);
								}}
							>
								<ListItemIcon>
									<CancelIcon />
								</ListItemIcon>
								<ListItemText primary='Avslutt hendelse' />
							</ListItem>
						</List>
						<Divider />
					</>
				)}
				<>
					<Typography variant='h6' style={{ paddingLeft: 10 }}>
						Generelt:
					</Typography>
					<List>
						<ListItem
							button
							component={Link}
							to='/'
							onClick={() => {
								props.setMenuOpen(false);
							}}
						>
							<ListItemIcon>
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText primary='Dashboard' />
						</ListItem>
						{/*}
                        <ListItem
                            button
                            component={Link}
                            to='/egne-hendelser'
                            onClick={() => {
                                props.setMenuOpen(false);
                            }}
                        >
                            
                            <ListItemIcon>
                                <ListAltIcon />
                            </ListItemIcon>
                            <ListItemText primary='Egne Hendelser' />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to='/delte-hendelser'
                            onClick={() => {
                                props.setMenuOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <OfflineShareIcon />
                            </ListItemIcon>
                            <ListItemText primary='Delte hendelser' />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <ArchiveIcon />
                            </ListItemIcon>
                            <ListItemText primary='Arkiverte hendelser' />
                        </ListItem>*/}
					</List>
					<Divider />
				</>
				<>
					{/*}
					<Typography variant='h6' style={{ paddingLeft: 10 }}>
						Admin:
					</Typography>
					<List>
						<ListItem
							button
							component={Link}
							to='/kunder'
							onClick={() => {
								props.setMenuOpen(false);
							}}
						>
							<ListItemIcon>
								<BusinessIcon />
							</ListItemIcon>
							<ListItemText primary='Kunder' />
						</ListItem>
					</List>
                        */}
				</>
			</Drawer>
		)
	);
}
