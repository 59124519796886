import { addDoc, collection, Timestamp } from '@firebase/firestore';
import {
	Autocomplete,
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	Box,
	Typography,
	Grid,
	Button,
	Avatar,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';

export default function LineSettingsDialog({
	setTmpLinePositions,
	setCreateLineMode,
	showLineSettingsDialog,
	setShowLineSettingsDialog,
	positions,
}) {
	const { id } = useParams();
	const [saving, setSaving] = useState(false);
	const { currentUser } = useAuth();
	const [includeInChat, setIncludeInChat] = useState(false);
	const [dashed, setDashed] = useState(false);
	const [color, setColor] = useState('black');
	const [info, setInfo] = useState('');

	const handleSaveFeature = async () => {
		if (positions.length < 3) return;

		setSaving(true);
		try {
			console.log(positions);
			await addDoc(collection(db, 'incidents', id, 'polylines'), {
				authorId: currentUser.uid,
				authorImgUrl: currentUser.photoURL,
				authorName: currentUser.displayName,
				created: Timestamp.now(),
				showInChat: includeInChat,
				isLine: true,
				dashed: dashed,
				message: info,
				color: color,
				positions: positions,
				dontShow: false,
			});
			setSaving(false);
			setTmpLinePositions([]);
			setCreateLineMode(false);
			setShowLineSettingsDialog(false);
		} catch (err) {
			setSaving(false);
			console.log(err);
		}
	};
	return (
		<Dialog
			maxWidth='md'
			fullWidth
			open={showLineSettingsDialog}
			onClose={() => {
				setTmpLinePositions([]);
				setCreateLineMode(false);
				setShowLineSettingsDialog(false);
			}}
		>
			<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
				Informasjon om Linje:
			</DialogTitle>
			<DialogContent>
				<TextField
					multiline
					rows={4}
					label='Informasjon om Linje'
					fullWidth
					value={info}
					onChange={(evt) => {
						setInfo(evt.target.value);
					}}
				/>
				<Select
					value={color}
					onChange={(evt) => {
						setColor(evt.target.value);
					}}
					fullWidth
					sx={{ marginTop: 1 }}
				>
					<MenuItem value='black'>Sort</MenuItem>
					<MenuItem value='red'>Rød</MenuItem>
					<MenuItem value='yellow'>Gul</MenuItem>
					<MenuItem value='green'>Grønn</MenuItem>
					<MenuItem value='blue'>Blå</MenuItem>
					<MenuItem value='orange'>Oransje</MenuItem>
				</Select>
				<FormGroup>
					<FormControlLabel
						disabled
						control={
							<Checkbox
								disabled
								checked={includeInChat}
								onChange={(evt) => {
									setIncludeInChat(evt.target.checked);
								}}
							/>
						}
						label='Inkluder i chat'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={dashed}
								onChange={(evt) => {
									setDashed(evt.target.checked);
								}}
							/>
						}
						label='Stiplet linje'
					/>
				</FormGroup>
				<Grid container spacing={1} sx={{ marginTop: 1 }}>
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='primary'
							fullWidth
							onClick={() => {
								handleSaveFeature();
							}}
							disabled={saving}
						>
							Lagre
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='secondary'
							fullWidth
							onClick={() => {
								setTmpLinePositions([]);
								setCreateLineMode(false);
								setShowLineSettingsDialog(false);
							}}
						>
							Avbryt
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
