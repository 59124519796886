import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	Typography,
} from '@mui/material';
import { deleteDoc, doc } from 'firebase/firestore';
import moment from 'moment';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';

export default function GroupStatus({ group, setProcessing }) {
	const { userData, currentUser } = useAuth();
	const handleRemoveRequest = async () => {
		await deleteDoc(
			doc(
				db,
				'groups',
				userData.group.groupId,
				'accessRequests',
				currentUser.uid
			)
		);
	};
	if (group) {
		setProcessing(false);
	}
	return (
		<Card raised>
			<CardHeader
				avatar={<Avatar src={group.groupLogoUrl} variant='square' />}
				title={<Typography variant='h5'>{group.groupName}</Typography>}
			/>
			<CardContent>
				<Typography variant='body2'>
					Du har bedt om tilgang til gruppen {group.groupName}
				</Typography>
				<Typography variant='body2'>
					Anmodningen ble sendt:{' '}
					{moment
						.unix(group.requested.seconds)
						.format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
				</Typography>
				<Typography variant='h6'>
					Status:{' '}
					{userData.group.status === 'pending' &&
						'Under behandling...'}
					{userData.group.status === 'rejected' && 'Avvist...'}
					{userData.group.status === 'admin' &&
						'Godkjent som administrator'}
					{userData.group.status === 'medlem' &&
						'Godkjent som medlem'}
				</Typography>
				<Button
					color='secondary'
					variant='outlined'
					onClick={handleRemoveRequest}
				>
					Fjern forespørsel
				</Button>
			</CardContent>
		</Card>
	);
}
