import {
	Button,
	Container,
	TextField,
	Card,
	CardHeader,
	CardContent,
	Avatar,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import logoImg from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import {
	Phone as PhoneIcon,
	Mail as MailIcon,
	VpnKey as VpnKeyIcon,
} from '@mui/icons-material';
import { RecaptchaVerifier } from '@firebase/auth';
import { auth } from '../../Services/firebase';

export default function LoginPhone() {
	const { loginPhone, currentUser } = useAuth();
	const [verifyActive, setVerifyActive] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [showMailLogin, setShowMailLogin] = useState(false);
	const [phone, setPhone] = useState('');
	const [verCode, setVerCode] = useState('');
	const [verifyResponse, setVerifyResponse] = useState(null);
	const history = useHistory();

	const setUpRecaptcha = () => {
		console.log('Setting up recaptcha');
		window.recaptchaVerifier = new RecaptchaVerifier(
			'recaptcha-container',
			{
				size: 'invisible',
			},
			auth
		);
	};
	const onSignInSubmit = (event) => {
		event.preventDefault();
		const phoneWithCountry = '+47' + phone;
		setUpRecaptcha();
		const appVerifier = window.recaptchaVerifier;
		loginPhone(phoneWithCountry, appVerifier)
			.then((res) => {
				setVerifyActive(true);
				setVerifyResponse(res);
			})
			.catch((error) => {
				window.location.reload();
				console.log(error);
			});
	};
	const onVerifyCode = (event) => {
		event.preventDefault();
		verifyResponse
			.confirm(verCode)
			.then((res) => {
				console.log(res);
				history.push('/');
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<>
			<Container maxWidth='sm' sx={{ marginTop: 20 }}>
				<Card sx={{ background: '#e3e3e3' }}>
					<CardHeader
						avatar={<Avatar src={logoImg} variant='square' />}
						title='SQUARE Innlogging:'
					/>
					<CardContent>
						<div id='recaptcha-container' />
						{!verifyActive && !showMailLogin ? (
							<form onSubmit={onSignInSubmit}>
								<TextField
									variant='outlined'
									fullWidth
									type='tel'
									label='Telefonnummer'
									sx={{ marginBottom: 2 }}
									value={phone}
									onChange={(evt) => {
										setPhone(evt.target.value);
									}}
									InputProps={{
										startAdornment: '+47 ',
									}}
								/>
								<Button
									id='sign-in-button'
									variant='contained'
									type='submit'
									fullWidth
									startIcon={<PhoneIcon />}
								>
									Logg inn/Registrer med telefon
								</Button>
							</form>
						) : (
							<form onSubmit={onVerifyCode}>
								<TextField
									variant='outlined'
									fullWidth
									label='Kode fra SMS'
									sx={{ marginBottom: 2 }}
									value={verCode}
									onChange={(evt) => {
										setVerCode(evt.target.value);
									}}
								/>
								<Button
									variant='contained'
									type='submit'
									fullWidth
									startIcon={<VpnKeyIcon />}
								>
									Bekreft kode
								</Button>
							</form>
						)}
						{/* <Button
							fullWidth
							variant='contained'
							color='info'
							sx={{ marginTop: 2 }}
							startIcon={<MailIcon />}
							onClick={() => {
								setShowMailLogin(true);
							}}
						>
							Logg inn/Registrer med mail
						</Button> */}
					</CardContent>
				</Card>
			</Container>
		</>
	);
}
