import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import {
	Alert,
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Container,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom/';
import { useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { storage } from '../../Services/firebase';

export default function Profile() {
	const history = useHistory();
	const { currentUser, updatePhoto, updateDisplayName } = useAuth();
	const [alert, setAlert] = useState(null);
	const [name, setName] = useState(currentUser.displayName || '');
	const phone = currentUser.phoneNumber;
	const [progress, setProgress] = useState(null);
	const [profilePicUrl, setProfilePicUrl] = useState(
		currentUser.photoURL || ''
	);
	const handleUploadPicture = (image) => {
		return new Promise((resolve, reject) => {
			if (image) {
				if (image.size < 4000000) {
					const storageRef = ref(
						storage,
						'/users/' + currentUser.uid
					);
					const uploadTask = uploadBytesResumable(storageRef, image);
					uploadTask.on(
						'state_changed',
						(snapshot) => {
							const progress =
								(snapshot.bytesTransferred /
									snapshot.totalBytes) *
								100;
							setProgress(progress);
						},
						(error) => {
							console.log(error.code);
							setProgress(null);
							setAlert({
								severity: 'error',
								msg: 'Feil under opplasting av bilde...',
							});
						},
						() => {
							getDownloadURL(uploadTask.snapshot.ref).then(
								async (downloadURL) => {
									setProgress(null);
									setProfilePicUrl(downloadURL);
									await updatePhoto(downloadURL).catch(
										(err) => {
											setAlert({
												severity: 'error',
												msg: 'Klarte ikke oppdatere bildet...',
											});
										}
									);
									setAlert({
										severity: 'success',
										msg: 'Bildet ble oppdatert...',
									});
									resolve('completed');
								}
							);
						}
					);
				} else {
					setAlert({
						severity: 'danger',
						msg: 'Størrelse kan ikke være over 4mb',
					});
				}
			}
		});
	};
	const removePicture = async () => {
		await updatePhoto('').catch((err) => {
			setAlert({
				severity: 'error',
				msg: 'Klarte ikke fjerne bildet...',
			});
		});
		setProfilePicUrl('');
		setAlert({ severity: 'success', msg: 'Bildet ble fjernet...' });
	};
	const handleUpdateProfile = async () => {
		if (!name || name.length < 3) {
			setAlert({ severity: 'warning', msg: 'Du må registrere navn' });
			return;
		}
		await updateDisplayName(name).catch((err) => {
			setAlert({
				severity: 'error',
				msg: 'Feil under oppdatering av profil...',
			});
		});
		setAlert({ severity: 'success', msg: 'Profilen ble oppdatert...' });
		history.go(0);
	};
	return (
		<Container>
			{currentUser && (
				<Card sx={{ marginTop: 1 }}>
					<CardHeader title='Min profil' />
					<CardContent>
						{alert && (
							<Alert
								severity={alert.severity}
								sx={{ marginBottom: 3 }}
							>
								{alert.msg}
							</Alert>
						)}
						<Grid container spacing={1}>
							{!currentUser.displayName && (
								<Grid item xs={12}>
									<Typography
										variant='body1'
										sx={{ marginBottom: 1 }}
									>
										For å delta i hendelser i SQUARE, er vi
										nødt til å vite hvem du er. Dette er
										både for å ha oversikt over hvem man
										samhandler med, og for å ha oversikt
										over hvem som har tilgang til de
										forskjellige hendelsene og gruppene. Du
										må minimum registrere et navn.
									</Typography>
									<Typography
										variant='body1'
										sx={{ marginBottom: 1 }}
									>
										Du vil også kunne laste opp et
										profilbilde...
									</Typography>
									<Typography
										variant='body1'
										sx={{ marginBottom: 1 }}
									>
										Hvis du er med i en gruppe vil navn og
										eventuelt bilde være synlig for de andre
										medlemmene. Hvis du er med i en hendelse
										vil navn og eventuelt bilde være synlig
										i listen over de som er med i hendelse,
										og ved hver interaksjon med kart
										og/eller chat.
									</Typography>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={2}
								sx={{
									alignSelf: 'center',
								}}
							>
								{!progress ? (
									<Avatar
										sx={{
											height: '150px',
											width: '150px',
										}}
										src={
											profilePicUrl ||
											currentUser.photoUrl
										}
									/>
								) : (
									<CircularProgress
										variant='determinate'
										value={progress}
										size={150}
									/>
								)}
								<Button
									fullWidth
									variant='text'
									color='info'
									component='label'
									sx={{ textAlign: 'center' }}
								>
									Velg bilde
									<input
										type='file'
										hidden
										accept='.jpg,.png'
										onChange={(evt) => {
											handleUploadPicture(
												evt.target.files[0]
											);
										}}
									/>
								</Button>
								<Button
									fullWidth
									variant='text'
									color='secondary'
									onClick={() => {
										removePicture();
									}}
								>
									Fjern bilde
								</Button>
							</Grid>
							<Grid item sm={12} md={10}>
								<TextField
									label='Navn'
									variant='outlined'
									fullWidth
									value={name}
									autoComplete='name'
									onChange={(evt) => {
										setName(evt.target.value);
									}}
									sx={{ marginBottom: 2 }}
								/>
								<TextField
									label='Telefon'
									variant='outlined'
									disabled
									fullWidth
									value={phone}
									type='tel'
									sx={{ marginBottom: 1 }}
								/>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Button
										fullWidth
										variant='contained'
										color='primary'
										onClick={async () => {
											await handleUpdateProfile();
										}}
									>
										Oppdater profil
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			)}
		</Container>
	);
}
