import { addDoc, collection, Timestamp } from '@firebase/firestore';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	Grid,
	Button,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useAuth } from '../../Context/AuthContext';
import { db } from '../../Services/firebase';

export default function PolygonSettingsDialog({
	setTmpPolyPositions,
	setCreatePolyMode,
	showPolySettingsDialog,
	setShowPolySettingsDialog,
	positions,
}) {
	const { id } = useParams();
	const [saving, setSaving] = useState(false);
	const { currentUser } = useAuth();
	const [includeInChat, setIncludeInChat] = useState(false);
	const [color, setColor] = useState('white');
	const [info, setInfo] = useState('');

	const handleSaveFeature = async () => {
		if (positions.length < 3) return;

		setSaving(true);
		try {
			let drawOrder = 4;
			if (color === 'red') {
				drawOrder = 3;
			}
			if (color === 'yellow') {
				drawOrder = 2;
			}
			if (color === 'green') {
				drawOrder = 1;
			}
			await addDoc(collection(db, 'incidents', id, 'polygons'), {
				authorId: currentUser.uid,
				authorImgUrl: currentUser.photoURL,
				authorName: currentUser.displayName,
				created: Timestamp.now(),
				showInChat: includeInChat,
				drawOrder: drawOrder,
				message: info,
				color: color,
				positions: positions,
				dontShow: false,
			}).catch((err) => {
				console.log(err.message);
			});
			setSaving(false);
			setTmpPolyPositions([]);
			setCreatePolyMode(false);
			setShowPolySettingsDialog(false);
		} catch (err) {
			setSaving(false);
			console.log(err);
		}
	};
	return (
		<Dialog
			maxWidth='md'
			fullWidth
			open={showPolySettingsDialog}
			onClose={() => {
				setTmpPolyPositions([]);
				setCreatePolyMode(false);
				setShowPolySettingsDialog(false);
			}}
		>
			<DialogTitle sx={{ paddingTop: 1, paddingBottom: 0 }}>
				Informasjon om polygon:
			</DialogTitle>
			<DialogContent>
				<TextField
					multiline
					rows={4}
					label='Informasjon om polygon'
					fullWidth
					value={info}
					onChange={(evt) => {
						setInfo(evt.target.value);
					}}
				/>
				<Select
					value={color}
					onChange={(evt) => {
						setColor(evt.target.value);
					}}
					fullWidth
					sx={{ marginTop: 1 }}
				>
					<MenuItem value='white'>Standard/Hvit</MenuItem>
					<MenuItem value='red'>Rød</MenuItem>
					<MenuItem value='yellow'>Gul</MenuItem>
					<MenuItem value='green'>Grønn</MenuItem>
				</Select>
				<FormGroup>
					<FormControlLabel
						disabled
						control={
							<Checkbox
								checked={includeInChat}
								disabled
								onChange={(evt) => {
									setIncludeInChat(evt.target.checked);
								}}
							/>
						}
						label='Inkluder i chat'
					/>
				</FormGroup>
				<Grid container spacing={1} sx={{ marginTop: 1 }}>
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='primary'
							fullWidth
							onClick={() => {
								handleSaveFeature();
							}}
							disabled={saving}
						>
							Lagre
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant='contained'
							color='secondary'
							fullWidth
							onClick={() => {
								setTmpPolyPositions([]);
								setCreatePolyMode(false);
								setShowPolySettingsDialog(false);
							}}
						>
							Avbryt
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
